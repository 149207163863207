import styled from '@emotion/styled/macro'
import { Link } from 'react-router-dom'
import isPropValid from '@emotion/is-prop-valid'
import { ReactComponent as HomeIconSvg } from '../../assets/icons/home.svg'
import { ReactComponent as VivadataIconSvg } from '../../assets/icons/vivadata.svg'
import { ReactComponent as RocketSvg } from '../../assets/icons/rocket.svg'
import { ReactComponent as DegreeSvg } from '../../assets/icons/degree.svg'
import { ReactComponent as AlumniSvg } from '../../assets/icons/alumni.svg'
import { ReactComponent as TutoSvg } from '../../assets/icons/tuto.svg'
import { ReactComponent as SetupSvg } from '../../assets/icons/wrench.svg'
import 'tippy.js/dist/tippy.css'

export const NavbarContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.navy200};
  color: white;
  width: 90px;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  flex-direction: column;

  .tippy-tooltip {
    width: 140px;
  }
  .tippy-content {
    padding: 0;
  }
  .tippy-popper {
    z-index: 10 !important;
  }
`

export const HomeIcon = styled(HomeIconSvg)`
  path {
    fill: white;
  }
  height: 20px;
  width: 20px;
`

export const RocketIcon = styled(RocketSvg)`
  path {
    fill: white;
  }
  height: 20px;
  width: 20px;
`

export const DegreeIcon = styled(DegreeSvg)`
  path {
    fill: white;
  }
  height: 20px;
  width: 20px;
`
export const SetupIcon = styled(SetupSvg)`
  path {
    fill: white;
  }
  height: 20px;
  width: 20px;
`
export const TutoIcon = styled(TutoSvg)`
  path {
    fill: white;
  }
  height: 20px;
  width: 20px;
`

export const AlumniIcon = styled(AlumniSvg)`
  path {
    fill: white;
  }
  height: 20px;
  width: 20px;
`

export const MenuItemsContainer = styled.div`
  position: absolute;
  top: 75px;
  display: flex;
  justify-content: center;
`

export const MenuItemWrapper = styled(Link, { shouldForwardProp: isPropValid })`
  position: absolute;
  top: ${({ order }) => order * 75}px;

  &:before {
    content: '';
    width: 7px;
    display: ${({ isActive }) => (isActive ? 'block' : 'none')};
    background-color: ${({ theme }) => theme.colors.azure100};
    position: absolute;
    top: -10px;
    bottom: -10px;
    left: -35px;
  }

  @keyframes wiggle {
    0% {
      transform: rotate(9deg);
    }
    10% {
      transform: rotate(-8deg);
    }
    20% {
      transform: rotate(7deg);
    }
    20% {
      transform: rotate(-6deg);
    }
    40% {
      transform: rotate(5deg);
    }
    50% {
      transform: rotate(-4deg);
    }
    60% {
      transform: rotate(3deg);
    }
    70% {
      transform: rotate(-2deg);
    }
    80% {
      transform: rotate(1deg);
    }
    90% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  &:hover svg {
    animation: wiggle 500ms;
  }
`

export const MainIconWrapper = styled(Link)`
  height: 40px;
  width: 40px;
  position: absolute;
  top: 30px;
`

export const VivadataIcon = styled(VivadataIconSvg)`
  height: 40px;
  width: 40px;
`

export const UserAvatar = styled.div`
  border-radius: 50%;
  cursor: pointer;
  height: 50px;
  width: 50px;
  background-image: url(${({ avatarUrl }) => avatarUrl});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;
  bottom: 30px;
`
