import React from 'react'

import { LoadingContainer, LoaderInner } from './style'

const Loader = ({ color = 'blue', isMiddlePage = true }) => (
  <LoadingContainer isMiddlePage={isMiddlePage}>
    <LoaderInner color={color}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </LoaderInner>
  </LoadingContainer>
)

export default Loader
