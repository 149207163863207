import React, { useState } from 'react'
import { Base64 } from 'js-base64'
import ProgressBar from '../../../../components/ProgressBar'
import CodeDisplay from '../../../../components/CodeDisplay'
import theme from '../../../../theme'
import {
  CardWrapper,
  StatusWrapper,
  FlashcardTab,
  StatusItem,
  FlashcardContent,
  UserAnswerLabel,
  UserAnswerWrapper,
  UserAnswer,
  NextButton,
  NextButtonWrapper,
  FlipButton,
  StyledInput,
  FlipIconWrapper,
  FlashcardCount,
  StatusText,
  EndWrapper,
  ScoreWrapper,
  RetryButton,
  QuestionSideWrapper,
  AnswerSideWrapper,
  QuestionContainer,
  AnswerContainer,
} from './style'

const Flashcard = ({
  flashcards,
  getNextFlashcard,
  resetFlashcards,
  successFlashcardList,
  failFlashcardList,
  currentFlashcard,
}) => {
  const [isQuestion, flipCard] = useState(true)
  const [userAnswer, setUserAnswer] = useState('')
  const [isAnswerKnown, setAnswerKnown] = useState(false)
  const isFlipAllowed = userAnswer.length > 2
  const finishedCount = failFlashcardList.length + successFlashcardList.length
  const totalCount = flashcards.length
  const failCount = failFlashcardList.length
  const successCount = successFlashcardList.length
  return (
    <FlashcardTab>
      {currentFlashcard ? (
        <>
          <StatusWrapper>
            <StatusItem>
              <ProgressBar
                percentage={(failCount / totalCount) * 100}
                height={10}
                primaryColor={theme.colors.navy100}
                secondaryColor={theme.colors.light100}
                borderRadius={2}
              />
              <StatusText>
                <div>TO REVIEW </div>
                <div>{failCount}</div>
              </StatusText>
            </StatusItem>
            <StatusItem>
              <ProgressBar
                percentage={(successCount / totalCount) * 100}
                height={10}
                primaryColor={theme.colors.purple100}
                secondaryColor={theme.colors.purple40}
                borderRadius={2}
              />
              <StatusText>
                <div>CORRECT</div> <div>{successCount}</div>
              </StatusText>
            </StatusItem>
          </StatusWrapper>
          <FlashcardContent>
            <ProgressBar
              percentage={((failCount + successCount) / totalCount) * 100}
              height={5}
              primaryColor={theme.colors.azure100}
              secondaryColor={theme.colors.light100}
              borderRadius={5}
            />
            <FlashcardCount>
              {finishedCount} / {totalCount}
            </FlashcardCount>

            <div>
              <CardWrapper>
                <QuestionSideWrapper isQuestion={isQuestion}>
                  <QuestionContainer>
                    <CodeDisplay
                      type="md"
                      content={Base64.decode(currentFlashcard.question)}
                      noPadding
                    />
                  </QuestionContainer>
                  <StyledInput
                    value={userAnswer}
                    onChange={event => setUserAnswer(event.target.value)}
                    name="userAnswer"
                    type="text"
                    placeholder="Your answer"
                  />
                  <FlipButton
                    onClick={() => {
                      if (isFlipAllowed) {
                        flipCard(!isQuestion)
                        setAnswerKnown(true)
                      } else {
                        alert('Please enter an answer 🧐')
                      }
                    }}
                  >
                    <FlipIconWrapper />
                    Flip card
                  </FlipButton>
                </QuestionSideWrapper>

                <AnswerSideWrapper isQuestion={isQuestion}>
                  <AnswerContainer>
                    <CodeDisplay
                      type="md"
                      content={Base64.decode(currentFlashcard.answer)}
                      noPadding
                    />
                  </AnswerContainer>
                  <FlipButton
                    onClick={() => {
                      if (isFlipAllowed) {
                        flipCard(!isQuestion)
                        setAnswerKnown(true)
                      } else {
                        alert('Please enter an answer 🧐')
                      }
                    }}
                  >
                    <FlipIconWrapper />
                    Flip card
                  </FlipButton>
                </AnswerSideWrapper>
              </CardWrapper>
              {isAnswerKnown && (
                <>
                  <UserAnswerWrapper>
                    <UserAnswerLabel>Your answer :</UserAnswerLabel>
                    <UserAnswer userAnswer={userAnswer}>
                      {userAnswer}
                    </UserAnswer>
                  </UserAnswerWrapper>

                  <NextButtonWrapper>
                    <NextButton
                      onClick={() => {
                        flipCard(true)
                        setAnswerKnown(false)
                        setUserAnswer('')
                        setTimeout(function() {
                          getNextFlashcard(currentFlashcard, false)
                        }, 300)
                      }}
                      isSuccess={false}
                    >
                      I didn't know
                    </NextButton>
                    <NextButton
                      onClick={() => {
                        flipCard(true)
                        setAnswerKnown(false)
                        setUserAnswer('')
                        setTimeout(function() {
                          getNextFlashcard(currentFlashcard, true)
                        }, 300)
                      }}
                      isSuccess={true}
                    >
                      I knew it
                    </NextButton>
                  </NextButtonWrapper>
                </>
              )}
            </div>
          </FlashcardContent>
        </>
      ) : (
        <EndWrapper onClick={() => resetFlashcards()}>
          Your score
          <ScoreWrapper>
            {successCount} / {totalCount}
          </ScoreWrapper>
          <ProgressBar
            percentage={(successCount / totalCount) * 100}
            height={15}
            primaryColor={theme.colors.purple100}
            secondaryColor={'white'}
            borderRadius={5}
          />
          <RetryButton>Try again</RetryButton>
        </EndWrapper>
      )}
    </FlashcardTab>
  )
}

export default Flashcard
