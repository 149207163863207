import axios from 'axios'
import Cookies from 'js-cookie'
import get from 'lodash/fp/get'

const createLoadingAction = action => `${action}:LOADING`
const createSuccessAction = ({ type, data }) => ({
  type: `${type}:SUCCESS`,
  payload: data,
})

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

const middleware = store => next => async action => {
  if (!get('payload.endpoint', action)) {
    return next(action)
  }
  const { dispatch } = store
  const { type, payload } = action
  const {
    endpoint,
    method = 'post',
    body,
    withLoading = true,
    params,
    timeout = 30000,
  } = payload
  const token = Cookies.get('jwt')

  if (withLoading) {
    dispatch({ type: createLoadingAction(type) })
  }

  try {
    const payload = await api.request(endpoint, {
      timeout,
      method,
      data: body,
      body,
      params,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        authorization: token,
      },
    })

    const { data } = payload
    dispatch({ type: 'FETCH:SUCCESSFUL' })

    return dispatch(createSuccessAction({ type, data }))
  } catch (error) {
    const { status } = error.response

    if (status === 401 || status === 403) {
      localStorage['activeTeam'] = ''
      localStorage['activeRepository'] = ''
      window.location = `${process.env.REACT_APP_API_URL}/login`
    }

    dispatch({ type: 'FETCH:ERROR' })
  }
}

export default middleware
