import styled from '@emotion/styled/macro'
import { Link } from 'react-router-dom'
import theme from '../../../../theme'

export const ChapterElem = styled(Link)`
  margin: 30px;
`

export const Wrapper = styled.div`
  border-radius: 5px;
  height: 260px;
  width: 240px;
  background-color: white;
  position: relative;
  box-shadow: 0px 2px 2px rgba(58, 58, 75, 0.25);
  transition: 200ms;
  &:hover {
    box-shadow: 0px 4px 4px rgba(58, 58, 75, 0.25);
  }
`

export const Title = styled.div`
  color: #2e2e3c;
  font-size: 14px;
  font-weight: 600;
`

export const SectionTitle = styled.div`
  color: ${theme.colors.purple100};
  font-size: 12px;
  font-weight: 600;
  position: absolute;
  right: 15px;
  bottom: 15px;
`

export const Description = styled.div`
  color: #3a3a4b;
  padding-top: 10px;
  font-size: 13px;
`

export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-left: 20px;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  padding-top: 160px;
`

export const Cover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 120px;
  /* border-bottom: 4px solid ${theme.colors.purple100}; */
  border-radius: 5px 5px 0 0;
  background-image: url(${({ background }) => background});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`
