import React from 'react'

import { ScrollTopWrapper } from './style'

const ScrollTop = ({ isNightShift, isTop }) => {
  return (
    <ScrollTopWrapper
      isTop={isTop}
      isNightShift={isNightShift}
      onClick={() =>
        document
          .getElementById('header_tabs')
          .scrollIntoView({ behavior: 'smooth' })
      }
    />
  )
}

export default ScrollTop
