const initialState = {
  name: '',
  login: '',
  avatarUrl: '',
  userRights: [],
  activeRepository: localStorage.getItem('activeRepository'),
  activeTeam: localStorage.getItem('activeTeam'),
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_USER_ALUMNI:SUCCESS': {
      // const { payload } = action
      // const { name, login, avatar_url, userRights } = payload
      return { ...state }
    }
    case 'GET_USER:SUCCESS': {
      const { payload } = action
      const { name, login, avatar_url, userRights } = payload
      return { ...state, name, login, avatarUrl: avatar_url, userRights }
    }
    case 'SET_ACTIVE_REPOSITORY': {
      const { payload } = action
      const { activeRepository, activeTeam } = payload
      return { ...state, activeRepository, activeTeam }
    }
    default:
      return state
  }
}
export default userReducer
