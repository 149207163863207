import styled from '@emotion/styled/macro'

// import { ReactComponent as SunIcon } from '../../assets/icons/sun.svg'
// import { ReactComponent as MoonIcon } from '../../assets/icons/moon.svg'

export const ToggleContainer = styled.div`
  padding: 2px;
`

// export const MoonIconWrapper = styled(MoonIcon)`
//   position: absolute;
//   left: 20px;
//   z-index: 2;
//   transition: 200ms;
//   height: 12px;
//   padding-top: 3px;
//   opacity: ${({ isActive }) => (isActive ? 1 : 0)};
//   transition: ${({ isActive }) => (isActive ? '200ms 200ms' : 0)};
// `

// export const SunIconWrapper = styled(SunIcon)`
//   position: absolute;
//   z-index: 2;
//   height: 12px;
//   padding-top: 3px;
//   transition: ${({ isActive }) => (!isActive ? '200ms 200ms' : 0)};
//   opacity: ${({ isActive }) => (isActive ? 0 : 1)};
// `

export const ToggleInner = styled.div`
  height: 18px;
  width: 35px;
  cursor: pointer;
  border-radius: 9px;
  display: flex;
  transition: background-color 100ms 100ms;
  background-color: ${({ isActive, theme }) =>
    isActive ? '#9FA0B7' : theme.colors.navy100};
  &:after {
    background-color: white;
    height: 18px;
    width: 18px;
    content: '';
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.23);
    border-radius: 100%;
    transition: transform 200ms ease;
    transform: scale(1.1)
      ${({ isActive }) => (isActive ? 'translate(15px)' : 'translate(0)')};
  }
`
