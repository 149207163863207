export const getAlumni = user => {
  return dispatch => {
    dispatch({
      type: 'GET_ALUMNI',
      payload: {
        endpoint: `alumni/get/${user}`,
        method: 'get',
      },
    })
  }
}

export const updateAlumni = (phone, slack, email) => {
  return dispatch => {
    dispatch({
      type: 'UPDATE_ALUMNI',
      payload: {
        timeout: 200000,
        endpoint: `alumni/update`,
        method: 'post',
        body: {
          phone,
          slack,
          email,
        },
      },
    })
  }
}

export const updateLinkedin = (linkedinUrl, id, updateType) => {
  return dispatch => {
    dispatch({
      type: 'UPDATE_LINKEDIN',
      payload: {
        timeout: 200000,
        endpoint: `alumni/updatelinkedin`,
        method: 'post',
        body: {
          id,
          linkedinUrl,
          updateType,
        },
      },
    })
  }
}

export const getAlumniList = () => {
  return dispatch => {
    dispatch({
      type: 'GET_ALUMNI_LIST',
      payload: {
        endpoint: `alumni/list`,
        method: 'get',
      },
    })
  }
}

export const searchAlumni = (searchString, filterParams, sortParam) => {
  return dispatch => {
    dispatch({
      type: 'SEARCH_ALUMNI',
      payload: {
        endpoint: `alumni/search`,
        method: 'post',
        body: {
          searchString,
          sortParam,
          filterParams,
        },
      },
    })
  }
}
