import styled from '@emotion/styled/macro'
import { keyframes } from '@emotion/core'

const rotateKeyframes = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}`

export const StyledInnerLoader = styled.div`
  position: relative;
  display: block;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: 50%;
  border: ${({ stroke }) => stroke}px solid #cec5e8;
  border-top: ${({ stroke }) => stroke}px solid ${({ color }) => color};
  transform: translateZ(0);
  animation: ${rotateKeyframes} 1s linear infinite;
`
