import styled from '@emotion/styled/macro'

export const ProgressContainer = styled.div`
  position: relative;
  width: 100%;
  height: ${({ height }) => height}px;
  background-color: ${({ color }) => color};
  border-radius: ${({ borderRadius }) => borderRadius}px;
  margin-bottom: 5px;
`

export const Progress = styled.div`
  position: absolute;
  width: ${({ percentage }) => percentage}%;
  height: ${({ height }) => height}px;
  background-color: ${({ color }) => color};
  border-radius: ${({ borderRadius }) => borderRadius}px;
`
