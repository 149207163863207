const initialState = {
  isNightShift: localStorage.isNightShift === 'true',
}

const appearanceReducer = (state = initialState, action) => {
  const { payload } = action

  switch (action.type) {
    case 'SET_NIGHT_SHIFT': {
      return { ...state, isNightShift: payload.isNightShift }
    }
    default:
      return state
  }
}
export default appearanceReducer
