import React, { useEffect, useState } from 'react'
import { Base64 } from 'js-base64'
import Modal from 'react-modal'
import { connect } from 'react-redux'
import {
  getAlumniList,
  updateLinkedin,
  searchAlumni,
} from '../../store/alumni/actions'
import SearchInput from './components/SearchInput'
import Sort from './components/Sort'
import {
  AlumniWrapper,
  FilterRow,
  AlumniCard,
  AlumniContainer,
  Title,
  CardCover,
  CardContent,
  Phone,
  StyledModal,
  inlineStyle,
  InnerProfilePicture,
  Header,
  Fullname,
  CardName,
  TeamTag,
  TagsWrapper,
  TagsWrapperInner,
  Description,
  Headline,
  Location,
  HeaderTitle,
  FirstRow,
  RightBlock,
  SkillTag,
  SkillsWrapper,
  ContentColumn,
  Content,
  ContentTitle,
  ExperienceScale,
  ExperienceStep,
  Bullet,
  Institution,
  ExpDate,
  SocialWrapper,
  LinkedinLogo,
  GithubLogo,
  EmailLogo,
  SlackLogo,
} from './style'

Modal.setAppElement('#root')

const capitalizeName = function(lower) {
  return (lower ? lower.toLowerCase() : lower).replace(
    // eslint-disable-next-line
    /(?:^|\s|['`‘’.-])[^\x00-\x60^\x7B-\xDF](?!(\s|$))/g,
    function(a) {
      return a.toUpperCase()
    }
  )
}

const Alumni = ({
  getAlumniList,
  alumni,
  appearance,
  updateLinkedin,
  searchAlumni,
}) => {
  const [selectedAlumni, setSelectedAlumni] = useState('')
  const [searchString, setSearch] = useState('')
  const [sortParam, setSort] = useState('')
  const [filterParams, setFilter] = useState([])
  const { alumniList, alumniListById, filterOptions, isSearchLoading } = alumni
  const { isNightShift } = appearance
  useEffect(() => {
    getAlumniList()
  }, [getAlumniList])

  const profile =
    selectedAlumni && !isSearchLoading ? alumniListById[selectedAlumni] : {}

  const {
    fullName,
    teams,
    description,
    experience = [],
    imageUrl,
    location,
    headline,
    skills = '',
    userLogin,
    linkedinUrl,
    email,
    slack,
    phone,
  } = profile

  const isEmptyProfile = !skills && experience.length === 0

  const jobs = experience
    ? experience.filter(elem => elem.type === 'job' && elem.startDate)
    : {}
  const schools = experience
    ? experience.filter(elem => elem.type === 'school' && elem.startDate)
    : {}

  const skillsArray = skills ? skills.split(',') : []

  return (
    <AlumniContainer isNightShift={isNightShift}>
      <Title isNightShift={isNightShift}>Alumni book</Title>
      <FilterRow>
        <SearchInput
          searchString={searchString}
          setSearch={setSearch}
          triggerSearch={string => {
            searchAlumni(string, filterParams, sortParam)
          }}
          previousStr={searchString}
          isLoading={isSearchLoading}
        />
        <Sort
          options={filterOptions}
          closeMenuOnSelect={false}
          isEmpty={!filterParams.length > 0}
          isLoading={isSearchLoading}
          handleChange={options => {
            if (!options) {
              setFilter([])
              searchAlumni(searchString, [], sortParam)
            } else {
              const params = options.map(opt => opt.value)
              setFilter(params)
              searchAlumni(searchString, params, sortParam)
            }
          }}
          isMulti
          type="filter"
          placeholder="Filter"
        />
        <Sort
          options={['name', 'batch', 'location']}
          isLoading={isSearchLoading}
          handleChange={option => {
            setSort(option.value)
            searchAlumni(searchString, filterParams, option.value)
          }}
          type="sort"
          placeholder="Sort by"
        />
      </FilterRow>
      <AlumniWrapper>
        {alumniList.map((elem, id) => {
          const { teams, imageUrl, fullName } = elem

          const cardTeam = teams.reduce((acc, elem) => {
            if (elem.indexOf('staff') !== -1 || acc.indexOf('staff') !== -1) {
              return 'staff'
            } else if (
              elem.indexOf('teacher') !== -1 ||
              acc.indexOf('teacher') !== -1
            ) {
              return 'teacher'
            } else if (elem !== '') {
              return elem
            }
            return acc
          }, '')

          const cardType = cardTeam.indexOf('batch') !== -1 ? 'batch' : cardTeam
          return (
            <AlumniCard
              key={`alumni_card_${id}`}
              onClick={() => {
                if (alumniListById[elem.id].linkedinUrl) {
                  updateLinkedin(
                    alumniListById[elem.id].linkedinUrl,
                    elem.id,
                    'allAlumnis'
                  )
                }
                if (!isSearchLoading) {
                  setSelectedAlumni(elem.id)
                }
              }}
            >
              <CardCover imageUrl={imageUrl}></CardCover>
              <CardContent>
                <CardName>{fullName && capitalizeName(fullName)}</CardName>

                <TagsWrapper>
                  <TeamTag key={`team_tag_${id}`} type={cardType} isFullProfile>
                    {cardTeam}
                  </TeamTag>
                </TagsWrapper>
              </CardContent>
            </AlumniCard>
          )
        })}
      </AlumniWrapper>
      <StyledModal
        isOpen={!!selectedAlumni && !isSearchLoading}
        shouldCloseOnOverlayClick
        onRequestClose={() => setSelectedAlumni('')}
        contentLabel="Alumni Modal"
        style={inlineStyle}
        isEmptyProfile={isEmptyProfile}
      >
        <Header>
          <FirstRow>
            <InnerProfilePicture background={imageUrl} />
            <RightBlock isEmptyProfile={isEmptyProfile}>
              <HeaderTitle>
                <Fullname>{fullName && capitalizeName(fullName)}</Fullname>
                {teams && (
                  <TagsWrapperInner>
                    {teams.map((team, id) => {
                      let type = 'batch'
                      if (team.toLowerCase().indexOf('staff') > -1) {
                        type = 'staff'
                      }
                      if (team.toLowerCase().indexOf('teacher') > -1) {
                        type = 'teacher'
                      }
                      return (
                        team && (
                          <TeamTag
                            key={`team_tag_${id}`}
                            type={type}
                            isFullProfile
                            isInner
                          >
                            {team}
                          </TeamTag>
                        )
                      )
                    })}
                  </TagsWrapperInner>
                )}
              </HeaderTitle>
              <Headline>{headline}</Headline>
              <Location>{location}</Location>
              <Phone isEmptyProfile={isEmptyProfile}>{phone}</Phone>
              <SocialWrapper isEmptyProfile={isEmptyProfile}>
                {linkedinUrl && (
                  <a
                    href={`${linkedinUrl}`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <LinkedinLogo />
                  </a>
                )}
                {userLogin && (
                  <a
                    href={`https://github.com/${userLogin}`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <GithubLogo />
                  </a>
                )}
                {email && (
                  <a
                    href={`mailto: ${email}`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <EmailLogo />
                  </a>
                )}
                {slack && (
                  <a
                    href={`https://vivadata-alumni.slack.com/team/${slack}`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <SlackLogo />
                  </a>
                )}
              </SocialWrapper>
            </RightBlock>
          </FirstRow>

          {description && (
            <Description>
              <div style={{ marginBottom: 10 }}>DESCRIPTION</div>
              {Base64.decode(description)}
            </Description>
          )}
        </Header>
        {!isEmptyProfile && (
          <Content>
            {schools.length > 0 && (
              <ContentColumn>
                <ContentTitle>EDUCATION</ContentTitle>
                <ExperienceScale>
                  {schools.map((elem, index) => (
                    <ExperienceStep key={`school_step_${index}`}>
                      <Bullet />
                      <Institution>{elem.institution}</Institution>
                      <div>{elem.title}</div>
                      <ExpDate>
                        {new Date(elem.startDate).getFullYear()} -{' '}
                        {elem.endDate}
                      </ExpDate>
                    </ExperienceStep>
                  ))}
                </ExperienceScale>
              </ContentColumn>
            )}
            {jobs.length > 0 && (
              <ContentColumn>
                <ContentTitle>EXPERIENCES</ContentTitle>
                <ExperienceScale>
                  {jobs.length > 0 &&
                    jobs.map((elem, index) => (
                      <ExperienceStep key={`job_step_${index}`}>
                        <Bullet />
                        <Institution>{elem.institution}</Institution>
                        <div>{elem.title}</div>
                        <ExpDate>
                          {new Date(elem.startDate).getFullYear()} -{' '}
                          {elem.endDate}
                        </ExpDate>
                      </ExperienceStep>
                    ))}
                </ExperienceScale>
              </ContentColumn>
            )}
            {skills && (
              <ContentColumn>
                <ContentTitle>SKILLS</ContentTitle>
                <SkillsWrapper>
                  {skillsArray.length > 0 &&
                    skillsArray.map((skill, index) => (
                      <SkillTag key={`skill_tag_${index}`}>{skill}</SkillTag>
                    ))}
                </SkillsWrapper>
              </ContentColumn>
            )}
          </Content>
        )}
      </StyledModal>
    </AlumniContainer>
  )
}

const mapStateToProps = state => {
  return state
}

const mapDispatchToProps = {
  getAlumniList,
  updateLinkedin,
  searchAlumni,
}

export default connect(mapStateToProps, mapDispatchToProps)(Alumni)
