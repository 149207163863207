import styled from '@emotion/styled/macro'
import Select from 'react-select'

export const StyledSelect = styled(Select)`
  width: ${({ isEmpty, type }) =>
    isEmpty && type === 'filter' ? '355px' : ''};
  border-radius: 8px;
  margin-right: 30px;
  position: relative;
  & > div {
    background-color: white;
  }

  @media screen and (max-width: 1160px) {
    margin-right: 30px;
    margin-left: 15px;
    margin-top: 10px;
  }
`

export const LoadingWrapper = styled.div`
  position: absolute;
  right: 7px;
  background-color: white;
`
