import React from 'react'
import { withRouter } from 'react-router'

import CodeDisplay from '../../../../components/CodeDisplay'

import { CodeWrapper } from '../../style'

const Lecture = ({ lecture, isNightShift, lectureType }) => {
  return (
    <CodeWrapper>
      {lecture && (
        <CodeDisplay
          type={lectureType}
          content={lecture}
          isNightShift={isNightShift}
          isCourses
        />
      )}
    </CodeWrapper>
  )
}

export default withRouter(Lecture)
