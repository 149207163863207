import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import Tippy from '@tippy.js/react'
import Cookies from 'js-cookie'

import SwitchModal from '../../components/SwitchModal'
import Popover from '../../components/Popover'
import {
  getUserAction,
  setActiveRepositoryAction,
} from '../../store/user/actions'
import { resetCourses } from '../../store/courses/actions'

import {
  NavbarContainer,
  HomeIcon,
  UserAvatar,
  VivadataIcon,
  MenuItemWrapper,
  DegreeIcon,
  RocketIcon,
  TutoIcon,
  SetupIcon,
  AlumniIcon,
  MenuItemsContainer,
  MainIconWrapper,
} from './style'

const menuItems = ['setup', 'courses', 'finalproject', 'tutorials', 'alumni']
const icons = {
  setup: <SetupIcon />,
  courses: <DegreeIcon />,
  finalproject: <RocketIcon />,
  tutorials: <TutoIcon />,
  alumni: <AlumniIcon />,
}

// const titles = {
//   setup: 'Setup',
//   courses: 'Courses',
//   finalproject: 'Project',
//   tutorials: 'Tutorials',
//   alumni: 'Alumni book',
// }

const Navbar = ({
  getUser,
  user,
  location,
  setActiveRepository,
  history,
  resetCourses,
}) => {
  const { pathname } = location
  const pathnameSplit = pathname.split('/')
  const activeContainer = pathnameSplit[1]

  useEffect(() => {
    const jwt = Cookies.get('jwt')
    if (jwt) {
      getUser()
    }
  }, [getUser])

  const { avatarUrl, userRights = [], activeRepository, activeTeam } = user
  const hiddenRoutes = userRights.reduce((acc, elem) => {
    if (elem.team === activeTeam && elem.hiddenRoutes) {
      return acc.concat(elem.hiddenRoutes)
    }
    return acc
  }, '')

  const [modalIsOpen, setIsOpen] = useState(false)
  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
  }

  const availableMenuItems = menuItems.filter(
    elem => hiddenRoutes.indexOf(elem) === -1
  )

  return (
    <NavbarContainer>
      <MainIconWrapper to="/">
        <VivadataIcon />
      </MainIconWrapper>
      <MenuItemsContainer>
        {/* <Tippy
          trigger="click"
          placement="right"
          arrow={false}
          content={<div></div>}
          interactive
          maxWidth={140}
        > */}
        <MenuItemWrapper to="/" order={1} isActive={activeContainer === ''}>
          <HomeIcon />
        </MenuItemWrapper>
        {/* </Tippy> */}

        {availableMenuItems.map((elem, index) => (
          <MenuItemWrapper
            to={`/${elem}`}
            order={index + 2}
            isActive={activeContainer === elem}
            key={`menu_item_${index}`}
          >
            {icons[elem]}
          </MenuItemWrapper>
        ))}
      </MenuItemsContainer>
      <Tippy
        trigger="click"
        placement="right"
        arrow={false}
        content={<Popover openModal={openModal} />}
        interactive
        maxWidth={140}
      >
        <UserAvatar avatarUrl={avatarUrl} />
      </Tippy>
      {userRights.length > 0 && (
        <>
          <SwitchModal
            isOpen={modalIsOpen}
            closeModal={closeModal}
            userRights={userRights}
            setActiveRepository={setActiveRepository}
            activeRepository={activeRepository}
            activeTeam={activeTeam}
            history={history}
            resetCourses={resetCourses}
          />
        </>
      )}
    </NavbarContainer>
  )
}

const mapStateToProps = state => {
  return state
}

const mapDispatchToProps = dispatch => {
  return {
    getUser: () => {
      dispatch(getUserAction())
    },
    setActiveRepository: (activeRepo, activeTeam) => {
      dispatch(setActiveRepositoryAction(activeRepo, activeTeam))
    },
    resetCourses: () => {
      dispatch(resetCourses())
    },
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navbar))
