import styled from '@emotion/styled/macro'

export const DashboardWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  overflow: scroll;
  position: absolute;
  left: 90px;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: ${({ theme, isNightShift }) =>
    isNightShift ? theme.colors.navy100 : theme.colors.light100};
`

export const ToggleWrapper = styled.div`
  position: absolute;
  padding: 15px 50px 0 0;
`

export const LoadingWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
`
