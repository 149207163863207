import React from 'react'
import { AccordionItem, AccordionItemHeading } from 'react-accessible-accordion'

import {
  AccordionItemButtonWrapper,
  PanelWrapper,
  ArrowItem,
  HeadingLabel,
  ArrowWrapper,
} from './style'

const AccordionMenu = ({ heading, panel, isToggled, isCourseList, uuid }) => {
  return (
    <AccordionItem uuid={uuid}>
      <AccordionItemHeading>
        <AccordionItemButtonWrapper>
          <HeadingLabel>{heading}</HeadingLabel>
          <ArrowWrapper>
            <ArrowItem isToggled={isToggled} />
          </ArrowWrapper>
        </AccordionItemButtonWrapper>
      </AccordionItemHeading>
      <PanelWrapper isCourseList={isCourseList}>{panel}</PanelWrapper>
    </AccordionItem>
  )
}

export default AccordionMenu
