import React from 'react'
import Modal from 'react-modal'

import {
  StyledModal,
  ModalHeader,
  CurriculumItem,
  ModalContent,
  inlineStyle,
  ArrowIcon,
  CurriculumTitle,
} from './style'

Modal.setAppElement('#root')

const SwitchModal = ({
  closeModal,
  isOpen,
  activeRepository,
  activeTeam,
  userRights,
  setActiveRepository,
  history,
  resetCourses,
}) => {
  const isRepositoryUnactive =
    userRights.filter(
      elem =>
        elem.team === activeTeam && elem.repositoryAccess === activeRepository
    ).length === 0

  return (
    <StyledModal
      isOpen={isOpen || isRepositoryUnactive}
      shouldCloseOnOverlayClick
      closeTimeoutMS={300}
      onRequestClose={closeModal}
      style={inlineStyle}
      contentLabel="Curriculum Modal"
    >
      <ModalHeader>Choose the curriculum you want to access</ModalHeader>
      <ModalContent>
        {userRights.map((elem, index) => {
          return (
            <CurriculumItem
              key={`curriculum_item_${index}`}
              onClick={() => {
                history.push('/')
                closeModal()
                resetCourses()
                setActiveRepository(elem.repositoryAccess, elem.team)
              }}
            >
              <CurriculumTitle isActive={activeTeam === elem.team}>
                {elem.team}
              </CurriculumTitle>
              <ArrowIcon />
            </CurriculumItem>
          )
        })}
      </ModalContent>
    </StyledModal>
  )
}

export default SwitchModal
