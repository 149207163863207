import styled from '@emotion/styled/macro'
import { ReactComponent as SearchSvg } from '../../../../assets/icons/search.svg'

export const StyledInput = styled.input`
  width: 320px;
  min-height: 40px;
  padding: 0 10px;
  background: white;
  box-shadow: 0px 2px 2px rgba(58, 58, 75, 0.25);
  border-radius: 8px;
  border: none;
  color: ${({ theme }) => theme.colors.navy100};
  &:active,
  &:focus {
    outline: none;
  }
  font-size: 18px;
  margin-left: 15px;
  position: relative;
`

export const LogoWrapper = styled.div`
  position: absolute;
  right: 10px;
`
export const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  border: none;
  width: 340px;
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 30px;
`

export const SearchIcon = styled(SearchSvg)`
  background-color: white;
  display: flex;
  align-items: center;
  border-radius: 8px;
  height: 100%;
  padding: 0 5px;
`
