const initialState = {
  isAlumniLoading: false,
  alumniList: [],
  alumniListById: [],
  errorMsg: '',
  successMsg: '',
  isSuccess: false,
  linkedinUrl: '',
  currentAlumniId: 0,
  filterOptions: [],
  isSearchLoading: false,
  phone: '',
  slack: '',
  email: '',
}

const contentReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALUMNI:SUCCESS': {
      const { payload } = action
      const { linkedinUrl, id, phone, email, slack } = payload
      return { ...state, linkedinUrl, currentAlumniId: id, phone, email, slack }
    }

    case 'UPDATE_ALUMNI:LOADING': {
      return { ...state }
    }
    case 'UPDATE_LINKEDIN:LOADING': {
      return { ...state, isAlumniLoading: true, errorMsg: '', successMsg: '' }
    }
    case 'UPDATE_LINKEDIN:SUCCESS': {
      const { payload } = action
      const { errorMsg = '', successMsg = '' } = payload
      return { ...state, isAlumniLoading: false, errorMsg, successMsg }
    }
    case 'GET_ALUMNI_LIST:LOADING': {
      return { ...state, errorMsg: '', successMsg: '' }
    }
    case 'GET_ALUMNI_LIST:SUCCESS': {
      const { payload } = action
      const { alumniList, alumniListById } = payload
      let filterOptions = []
      alumniList.map(elem => {
        elem.teams.map(team => {
          if (!filterOptions.includes(team) && team !== '') {
            filterOptions.push(team)
          }
          return ''
        })
        return ''
      })
      filterOptions.sort()

      return {
        ...state,
        alumniList: alumniList,
        alumniListById,
        filterOptions,
      }
    }

    case 'SEARCH_ALUMNI:LOADING': {
      return {
        ...state,
        isSearchLoading: true,
      }
    }
    case 'SEARCH_ALUMNI:SUCCESS': {
      const { payload } = action
      return {
        ...state,
        alumniList: payload.alumniList,
        alumniListById: payload.alumniListById,
        isSearchLoading: false,
      }
    }
    default:
      return state
  }
}
export default contentReducer
