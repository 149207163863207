import styled from '@emotion/styled/macro'
import Modal from 'react-modal'
import theme from '../../theme'
import { ReactComponent as GithubSvg } from '../../assets/icons/github.svg'
import { ReactComponent as LinkedinSvg } from '../../assets/icons/linkedin.svg'
import { ReactComponent as EmailSvg } from '../../assets/icons/email.svg'
import { ReactComponent as SlackSvg } from '../../assets/icons/slack.svg'

export const AlumniCard = styled.div`
  position: relative;
  height: 250px;
  width: 200px;
  margin: 15px;
  border-radius: 4px;
  cursor: pointer;
`

export const CardCover = styled.div`
  background-image: url(${({ imageUrl }) => imageUrl});
  background-color: ${theme.colors.grey40};
  border-radius: 4px 4px 0 0;
  width: 200px;
  height: 170px;
  background-size: cover;
  background-position: center;
`
export const CardTitle = styled.div`
  padding: 15px;
`

export const AlumniContainer = styled.div`
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  left: 105px;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme, isNightShift }) =>
    isNightShift ? theme.colors.navy100 : theme.colors.light100};
`

export const Title = styled.span`
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: ${({ theme, isNightShift }) =>
    isNightShift ? 'white' : theme.colors.navy400};
  padding: 30px 15px;
`

export const AlumniWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const inlineStyle = {
  overlay: {
    zIndex: 11,
    backgroundColor: 'rgba(58, 58, 75, 0.59)',
  },
  content: {
    width: '80%',
    color: theme.colors.navy100,
    borderRadius: 8,
    position: 'absolute',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
  },
}

export const StyledModal = styled(Modal)`
  height: ${({ isEmptyProfile }) => (isEmptyProfile ? '40%' : '90%')};
  background-color: ${({ isEmptyProfile, theme }) =>
    isEmptyProfile ? theme.colors.navy50 : 'white'};
  overflow: auto;
  margin: 20px 0;
`

export const InnerProfilePicture = styled.div`
  background-image: url(${({ background }) => background});
  background-color: ${theme.colors.light100};
  height: 180px;
  width: 180px;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  margin-left: 90px;
`

export const Header = styled.div`
  background-color: ${theme.colors.navy50};
  border-radius: 8px 8px 0 0;
  color: white;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 50px;
`
export const Fullname = styled.div`
  padding-right: 20px;
`

const tagColors = {
  staff: 'error',
  teacher: 'azure100',
  batch: 'purple100',
}

export const TeamTag = styled.div`
  padding: ${({ isInner }) => (isInner ? '5px 3px' : '0px 3px')};
  border-radius: 2px;
  font-size: 12px;
  font-weight: 200;
  white-space: nowrap;
  color: white;
  background-color: ${({ theme, type }) =>
    tagColors[type] ? theme.colors[tagColors[type]] : theme.colors.error};
  margin-right: ${({ isFullProfile }) => (isFullProfile ? '10px' : 0)};
`

export const Description = styled.div`
  width: 70%;
  padding-top: 40px;
`
export const Headline = styled.div``
export const Location = styled.div``

export const HeaderTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 18px;
`

export const FirstRow = styled.div`
  padding-top: 90px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`

export const RightBlock = styled.div`
  height: ${({ isEmptyProfile }) => (isEmptyProfile ? '100%' : '150px')};
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  padding-left: 50px;
`

export const ElemTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #3a3a4b;
`

export const SkillTag = styled.div`
  background-color: #3a3a4b;
  border-radius: 4px;
  padding: 5px 10px;
  margin: 5px 5px;
`

export const SkillsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  color: white;
`

export const ContentColumn = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  &:first-of-type {
    padding-left: 80px;
  }
`

export const Content = styled.div`
  display: flex;
`

export const ContentTitle = styled.div`
  margin-top: 50px;
  margin-bottom: 40px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #3a3a4b;
`

export const ExperienceScale = styled.div`
  position: relative;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 5px;
    bottom: -30px;
    width: 2px;
    background: rgb(81, 82, 108);
    background: linear-gradient(
      180deg,
      rgba(81, 82, 108, 1) 90%,
      rgba(255, 255, 255, 1) 100%
    );
  }
`

export const ExperienceStep = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  padding-bottom: 20px;
  color: rgb(81, 82, 108);
  font-size: 14px;
  line-height: 16px;
  padding-right: 20px;
`

export const Bullet = styled.div`
  position: absolute;
  height: 10px;
  width: 10px;
  background: rgb(81, 82, 108);
  left: -4px;
  border-radius: 5px;
  margin-top: 5px;
`

export const Institution = styled.div`
  font-size: 16px;
`
export const ExpDate = styled.div`
  color: #8d8d96;
`

export const SocialWrapper = styled.div`
  display: flex;
  margin-top: ${({ isEmptyProfile }) => (isEmptyProfile ? '20px' : '0')};
`

export const LinkedinLogo = styled(LinkedinSvg)`
  height: 20px;
  width: 20px;
`

export const GithubLogo = styled(GithubSvg)`
  height: 20px;
  width: 20px;
  margin-left: 30px;
`

export const EmailLogo = styled(EmailSvg)`
  height: 20px;
  width: 20px;
  margin-left: 30px;
`

export const SlackLogo = styled(SlackSvg)`
  height: 20px;
  width: 20px;
  margin-left: 30px;
`

export const FilterRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  @media screen and (max-width: 1160px) {
    flex-direction: column;
    width: 400px;
  }
`

export const CardContent = styled.div`
  width: 200px;
  height: 80px;
  background-color: white;
  border-radius: 0 0 4px 4px;
  font-size: 18px;
  line-height: 23px;
  align-items: center;
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.colors.navy100};
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`

export const CardName = styled.div`
  font-size: 16px;
  font-weight: 500;
  text-align: center;
`

export const TagsWrapper = styled.div`
  align-self: flex-end;
`

export const TagsWrapperInner = styled.div`
  margin-right: 15px;
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  & > div {
    margin-bottom: 3px;
  }
`

export const Phone = styled.div`
  margin-top: ${({ isEmptyProfile }) => (isEmptyProfile ? '20px' : '0')};
`
