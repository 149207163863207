import { combineReducers } from 'redux'
import appearance from './appearance/reducer'
import user from './user/reducer'
import courses from './courses/reducer'
import content from './content/reducer'
import alumni from './alumni/reducer'

export default combineReducers({
  user,
  courses,
  content,
  alumni,
  appearance,
})
