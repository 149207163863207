import React from 'react'
import { StyledInput, InputWrapper, SearchIcon, LogoWrapper } from './style'
import InnerLoader from '../../../../components/InnerLoader'

const SearchInput = ({
  setSearch,
  searchString,
  triggerSearch,
  isLoading,
  previousStr,
}) => {
  const handleChange = e => {
    const isDeleting = e.target.value.length < previousStr.length
    if (!isLoading) {
      setSearch(e.target.value)
      if (
        (isDeleting && e.target.value.length === 0) ||
        e.target.value.length > 2
      ) {
        triggerSearch(e.target.value)
      }
    }
  }

  return (
    <InputWrapper>
      <StyledInput
        value={searchString}
        onChange={handleChange}
        placeholder={'Search for a student, a location...'}
      />
      <LogoWrapper>
        {isLoading ? <InnerLoader size={12} /> : <SearchIcon />}
      </LogoWrapper>
    </InputWrapper>
  )
}

export default SearchInput
