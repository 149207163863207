import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import {
  getSections,
  getChapter,
  getChallenges,
  getChallenge,
  getNextFlashcard,
  resetFlashcards,
} from '../../store/courses/actions'
import CoursesList from './components/CoursesList'
import ScrollTop from '../../components/ScrollTop'
import Chapter from './components/Chapter'
import NightShift from '../NightShift'

import { CoursesWrapper, NightShiftWrapper } from './style'

const Courses = ({
  getSections,
  courses,
  match,
  getChapter,
  getChallenges,
  getChallenge,
  getNextFlashcard,
  resetFlashcards,
  appearance,
  user,
}) => {
  const chapterName = match.params.chapterName
  const { sections, chapter, unavailableStyle } = courses
  const { isNightShift } = appearance
  const { activeRepository, activeTeam } = user
  const [isTop, setIsTop] = useState(true)

  const scrollHandler = () => {
    const chapterWrapper = document.getElementById('chapter_wrapper')
    const scrollTop = chapterWrapper.scrollTop
    if (scrollTop > 300 && isTop === true) {
      setIsTop(false)
    } else if (scrollTop < 300 && isTop === false) {
      setIsTop(true)
    }
  }

  useEffect(() => {
    getSections(activeRepository, activeTeam, 'curriculum')
    if (chapterName) {
      getChapter(chapterName, activeRepository, activeTeam, 'curriculum')
      getChallenges(chapterName, activeRepository, activeTeam, 'curriculum')
    }
  }, [
    getSections,
    getChapter,
    chapterName,
    getChallenges,
    activeRepository,
    activeTeam,
  ])

  return (
    <CoursesWrapper>
      <CoursesList
        sections={sections}
        activeItem={chapter.name}
        unavailableStyle={unavailableStyle}
      />
      {chapterName && (
        <>
          <Chapter
            courses={courses}
            getNextFlashcard={getNextFlashcard}
            resetFlashcards={resetFlashcards}
            getChallenge={getChallenge}
            isNightShift={isNightShift}
            scrollHandler={scrollHandler}
            type="curriculum"
          />
          <ScrollTop isNightShift={isNightShift} isTop={isTop} />
        </>
      )}
      <NightShiftWrapper>
        <NightShift />
      </NightShiftWrapper>
    </CoursesWrapper>
  )
}

const mapStateToProps = state => {
  return state
}

const mapDispatchToProps = {
  getSections,
  getChapter,
  getChallenges,
  getChallenge,
  getNextFlashcard,
  resetFlashcards,
}

export default connect(mapStateToProps, mapDispatchToProps)(Courses)
