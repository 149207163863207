import React from 'react'
import Navbar from '../../containers/Navbar'
import { Global } from '@emotion/core'
import { connect } from 'react-redux'

import ExceptionHandler from '../ExceptionHandler'

import theme from '../../theme'
import { LayoutContainer, GlobalStyle } from './style'

const Layout = ({ children, isNightShift }) => {
  return (
    <LayoutContainer isNightShift={isNightShift}>
      <Navbar />
      <ExceptionHandler exceptionType="mobile" />
      <Global styles={GlobalStyle(theme, isNightShift)} />
      {children}
    </LayoutContainer>
  )
}

const mapStateToProps = ({ appearance }) => {
  return appearance
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Layout)
