import styled from '@emotion/styled/macro'
import Modal from 'react-modal'
import theme from '../../theme'
import { ReactComponent as Arrow } from '../../assets/icons/arrow.svg'

export const StyledModal = styled(Modal)``

export const ModalHeader = styled.div`
  background-color: ${({ theme }) => theme.colors.silver40};
  border-bottom: 1px solid ${({ theme }) => theme.colors.silver100};
  display: flex;
  justify-content: center;
  padding: 25px;
  border-radius: 8px 8px 0 0;
`

export const ModalContent = styled.div`
  max-height: 500px;
  overflow-y: scroll;
`

export const CurriculumItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    cursor: pointer;
    span {
      text-decoration: underline;
    }
  }
`

export const CurriculumTitle = styled.span`
  padding-left: 50px;
  padding: 25px;
  color: ${({ isActive }) =>
    isActive ? theme.colors.silver100 : theme.colors.navy100};
`

export const ArrowIcon = styled(Arrow)`
  transform: rotate(-90deg) translate(0, -25px);
  & rect {
    fill: ${theme.colors.navy100};
  }
`

export const inlineStyle = {
  overlay: {
    zIndex: 11,
    backgroundColor: 'rgba(58, 58, 75, 0.59)',
  },
  content: {
    width: '50%',
    backgroundColor: 'white',
    color: theme.colors.navy100,
    borderRadius: 8,
    position: 'absolute',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
  },
}
