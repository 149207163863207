export const getUserAction = () => {
  return dispatch => {
    dispatch({
      type: 'GET_USER',
      payload: {
        endpoint: '/user',
        method: 'get',
      },
    })
  }
}

export const setActiveRepositoryAction = (activeRepository, activeTeam) => {
  localStorage.setItem('activeRepository', activeRepository)
  localStorage.setItem('activeTeam', activeTeam)

  return dispatch => {
    dispatch({
      type: 'SET_ACTIVE_REPOSITORY',
      payload: {
        activeRepository,
        activeTeam,
      },
    })
  }
}
