import styled from '@emotion/styled/macro'

export const ProfileContainer = styled.div`
  justify-content: flex-end;
  overflow: scroll;
  position: absolute;
  left: 90px;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: ${({ theme, isNightShift }) =>
    isNightShift ? theme.colors.navy100 : theme.colors.light100};
  color: ${({ theme, isNightShift }) =>
    isNightShift ? theme.colors.light100 : theme.colors.navy100};
`

export const ToggleWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  padding: 15px 50px 0 0;
`

export const ProfilePicture = styled.div`
  height: 150px;
  width: 150px;
  border-radius: 8px;
  background: center / contain no-repeat url(${({ background }) => background});
`

export const ProfileWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 100px;
`

export const Title = styled.span`
  font-weight: 600;
  font-size: 20px;
  padding-bottom: 10px;
`

export const InfosWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 30px;
  justify-content: space-between;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`

export const StyledInput = styled.input`
  border-radius: 10px;
  height: 25px;
  border: solid 1px ${({ theme }) => theme.colors.grey40};
  font-size: 14px;
  padding-left: 5px;
  transition: 200ms;
  color: ${({ theme }) => theme.colors.navy100};
  &:focus,
  &:active {
    border: solid 1px ${({ theme }) => theme.colors.navy100};
    outline: none;
  }
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`

export const SubmitButton = styled.button`
  border-radius: 10px;
  outline: none;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  line-height: 19px;
  font-size: 12px;
  border: none;
  cursor: pointer;
  transition: 0.2s;
  padding: 0 15px;
  display: flex;
  justify-content: center;
  height: 30px;
  min-width: 88px;
  background-color: ${({ theme }) => theme.colors.purple100};
  color: white;
  align-self: center;
  align-items: center;
  &:active {
    background-color: #3e2b6e;
  }
`
