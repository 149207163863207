import styled from '@emotion/styled/macro'

export const Button = styled.button`
  border-radius: 10px;
  outline: none;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  line-height: 19px;
  font-size: 12px;
  border: none;
  cursor: pointer;
  transition: 0.2s;
  padding: 0 15px;
  display: flex;
  justify-content: center;
  height: 35px;
  min-width: 88px;
  position: absolute;
  right: 0;
  top: 8px;
  right: 8px;
  padding: 0 15px;
  background-color: ${({ theme }) => theme.colors.purple100};
  color: white;
  &:active {
    background-color: #3e2b6e;
  }
  align-items: center;
  ${({ isDisabled, theme }) =>
    isDisabled
      ? `cursor: not-allowed;
          background-color: ${theme.colors.grey40};
          cursor: auto;
          `
      : ''}
`

export const FormInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`
export const FormInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  width: 377px;
`
export const FormInputElement = styled.input`
  resize: none;
  min-width: 260px;
  border-radius: 10px;
  height: 51px;
  border: solid 1px ${({ theme }) => theme.colors.grey40};
  font-size: 14px;
  padding: 0 100px 0 15px;
  transition: 200ms;
  color: ${({ theme }) => theme.colors.navy100};
  ${({ isError, theme }) =>
    isError ? `border: solid 1px ${theme.colors.error};` : ''}
  &:focus {
    border: solid 1px ${({ theme }) => theme.colors.navy100};
    outline: none;
  }
`

export const ErrorMessage = styled.div`
  margin: 5px 0 0 20px;
  position: absolute;
  color: ${({ theme }) => theme.colors.error};
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  bottom: -20px;
`

export const SuccessMessage = styled(ErrorMessage)`
  color: ${({ theme }) => theme.colors.success};
`

export const LoaderWrapper = styled.div`
  position: absolute;
  transform: scale(0.4);
  top: -14px;
`
