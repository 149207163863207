import styled from '@emotion/styled/macro'
import Collapse from 'rc-collapse'
// eslint-disable-line no-unused-vars
import 'rc-collapse/assets/index.css' // Must stay here
import { ReactComponent as Arrow } from '../../../../assets/icons/arrow.svg'

const Panel = Collapse.Panel

export const ChallengeContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const ChallengeListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 175px;
  @media screen and (max-width: 1300px) {
    min-width: 150px;
  }
`

export const ChallengeListItem = styled.div`
  padding-bottom: 10px;
  cursor: pointer;
  color: ${({ selected, theme, isNightShift }) => {
    const defaultColor = isNightShift
      ? theme.colors.silver40
      : theme.colors.navy500
    return selected ? theme.colors.azure100 : defaultColor
  }};
`

export const CollapseWrapper = styled(Collapse)`
  align-content: start;
  & .rc-collapse-header {
    display: flex;
    flex-direction: row-reverse;
    color: ${({ isNightShift, theme }) =>
      isNightShift ? theme.colors.silver40 : theme.colors.navy500} !important;
    justify-content: space-between;
    position: relative;
    &:focus {
      outline: none;
    }

    &:after {
      content: '';
      position: absolute;
      height: 1px;
      width: 100%;
      top: 100%;
      left: 0;
      background-color: ${({ isNightShift, theme }) =>
        isNightShift ? theme.colors.silver40 : theme.colors.navy500};
    }
  }
  & .rc-collapse-item > .rc-collapse-header > svg {
    transition: 200ms;
    transform: rotate(-90deg);
  }
  & .rc-collapse-item-active > .rc-collapse-header > svg {
    transform: rotate(0deg);
  }

  & .rc-collapse-item {
    border-top: none;
  }

  & .rc-collapse-content {
    padding: 0;
  }

  width: 100%;
  background-color: transparent;
  border: none;
`

export const ArrowWrapper = styled(Arrow)`
  rect {
    fill: ${({ isNightShift, theme }) =>
      isNightShift ? theme.colors.silver40 : theme.colors.navy500};
  }
`

export const PanelWrapper = styled(Panel)`
  & > .rc-collapse-content {
    background-color: transparent;
  }
  border: none;
  /* width: ${({ type }) => (type === 'ipynb' ? '80%' : '100%')}; */
`
