import styled from '@emotion/styled/macro'
import { Link } from 'react-router-dom'
import isPropValid from '@emotion/is-prop-valid'
import { Accordion } from 'react-accessible-accordion'

export const ChaptersListWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const ChapterItem = styled(Link, { shouldForwardProp: isPropValid })`
  display: flex;
  padding: 12px 45px;
  color: ${({ isActive, theme }) =>
    isActive ? theme.colors.azure100 : theme.colors.silver40};
  &:hover {
    background-color: ${({ theme }) => theme.colors.navy500};
  }
`

export const UnavailableChapterItem = styled.div`
  padding: 12px 45px;
  color: ${({ theme }) => theme.colors.navy40};
`

export const CoursesListWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.navy100};
  border-radius: 0 8px 8px 0;
  width: 250px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 90px;
  & > div {
    border-radius: 0 8px 8px 0;
  }
  overflow-y: scroll;
  @media screen and (max-width: 1100px) {
    width: 175px;
  }
`

export const AccordionWrapper = styled(Accordion)`
  padding-top: 50px;
  background-color: ${({ theme }) => theme.colors.navy100};
  max-width: 250px;
`
