import React from 'react'

import { ExceptionHandlerWrapper, HomeLink, ExceptionText } from './style'

const exceptionContent = {
  404: {
    text: "Oops, looks like the page you're looking for doesn't exist",
    content: <HomeLink to="/">Go back to the Home Page</HomeLink>,
  },
  unauthorized: {
    text:
      "Looks like you're not part of any Vivadata Team, or you don't have access to this curriculum. Please contact Vivadata admins",
    content: <HomeLink to="/">Go back to the Home Page</HomeLink>,
  },
  mobile: {
    text:
      'Vivadatapp is not available on mobile for now. Please try it on a bigger device',
  },
}
const ExceptionHandler = ({ exceptionType }) => {
  return (
    <ExceptionHandlerWrapper isMobile={exceptionType === 'mobile'}>
      <ExceptionText>{exceptionContent[exceptionType].text}</ExceptionText>
      {exceptionContent[exceptionType].content}
    </ExceptionHandlerWrapper>
  )
}

export default ExceptionHandler
