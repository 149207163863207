import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import {
  getSections,
  getChapter,
  getChallenges,
  getChallenge,
  getNextFlashcard,
  resetFlashcards,
} from '../../store/courses/actions'
// import CoursesList from './components/CoursesList'
import ScrollTop from '../../components/ScrollTop'
import NightShift from '../NightShift'
import Chapter from '../Courses/components/Chapter'
import ChaptersGrid from './components/ChaptersGrid'

import { Title, Subtitle, Container } from './style'
import { NightShiftWrapper } from '../Courses/style'

const Tutorials = ({
  getSections,
  courses,
  match,
  getChapter,
  getChallenges,
  getChallenge,
  getNextFlashcard,
  resetFlashcards,
  appearance,
  user,
  type,
}) => {
  const chapterName = match.params.chapterName
  const { sections, storeType } = courses
  const { isNightShift } = appearance
  const { activeRepository, activeTeam } = user
  const [isTop, setIsTop] = useState(true)

  const scrollHandler = () => {
    const chapterWrapper = document.getElementById('chapter_wrapper')
    const scrollTop = chapterWrapper.scrollTop
    if (scrollTop > 300 && isTop === true) {
      setIsTop(false)
    } else if (scrollTop < 300 && isTop === false) {
      setIsTop(true)
    }
  }

  useEffect(() => {
    getSections(activeRepository, activeTeam, type)
    if (chapterName) {
      getChapter(chapterName, activeRepository, activeTeam, type)
      getChallenges(chapterName, activeRepository, activeTeam, type)
    }
  }, [
    getSections,
    getChapter,
    chapterName,
    getChallenges,
    activeRepository,
    activeTeam,
    type,
  ])

  const chapters = sections.reduce((acc, elem) => {
    const test = elem.chapters.map(
      ({ id, name, coverDescription, coverUrl }) => ({
        sectionName: elem.name,
        id,
        name,
        coverDescription,
        coverUrl,
      })
    )

    return [...acc, ...test]
  }, [])

  return (
    <Container isNightShift={isNightShift}>
      <NightShiftWrapper>
        <NightShift />
      </NightShiftWrapper>
      <Title isNightShift={isNightShift} to={`/${type}`}>
        {type === 'setup' ? 'Setup' : 'Tutorials'}
      </Title>
      {!chapterName && type === 'setup' && (
        <Subtitle isNightShift={isNightShift}>
          Please choose your operating system (OS):
        </Subtitle>
      )}
      {chapterName ? (
        <>
          <Chapter
            courses={courses}
            getNextFlashcard={getNextFlashcard}
            resetFlashcards={resetFlashcards}
            getChallenge={getChallenge}
            isNightShift={isNightShift}
            scrollHandler={scrollHandler}
            type={type}
          />
          <ScrollTop isNightShift={isNightShift} isTop={isTop} />
        </>
      ) : (
        <ChaptersGrid chapters={chapters} type={type} storeType={storeType} />
      )}
    </Container>
  )
}

const mapStateToProps = state => {
  return state
}

const mapDispatchToProps = {
  getSections,
  getChapter,
  getChallenges,
  getChallenge,
  getNextFlashcard,
  resetFlashcards,
}

export default connect(mapStateToProps, mapDispatchToProps)(Tutorials)
