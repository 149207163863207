import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  getAlumni,
  updateAlumni,
  updateLinkedin,
} from '../../store/alumni/actions'

import NightShift from '../NightShift'
import FormInput from '../../components/FormInput'
import {
  ProfileContainer,
  ProfileWrapper,
  ToggleWrapper,
  ProfilePicture,
  InfosWrapper,
  Title,
  Column,
  StyledInput,
  Row,
  SubmitButton,
} from './style'

const UserProfile = ({
  appearance,
  user,
  alumni,
  getAlumni,
  updateAlumni,
  updateLinkedin,
}) => {
  const { isNightShift } = appearance
  const { login, avatarUrl } = user
  const [phoneState, setPhone] = useState('')
  const [slackState, setSlack] = useState('')
  const [emailState, setEmail] = useState('')
  const {
    isAlumniLoading,
    errorMsg,
    successMsg,
    linkedinUrl,
    currentAlumniId,
    phone,
    email,
    slack,
  } = alumni

  useEffect(() => {
    if (login) {
      getAlumni(login)
    }
    if (phone) {
      setPhone(phone)
    }
    if (slack) {
      setSlack(slack)
    }
    if (email) {
      setEmail(email)
    }
  }, [login, getAlumni, phone, email, slack])
  const handleSubmit = value => {
    updateLinkedin(value, currentAlumniId, 'editUser')
  }

  return (
    <ProfileContainer isNightShift={isNightShift}>
      <ProfileWrapper>
        <Title>User profile</Title>
        <Row>
          <ProfilePicture background={avatarUrl} />
          <InfosWrapper>
            <Column>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <StyledInput
                  placeholder="Phone"
                  value={phoneState}
                  onChange={e => setPhone(e.target.value)}
                />
                <StyledInput
                  placeholder="Slack ID"
                  value={slackState}
                  onChange={e => setSlack(e.target.value)}
                  style={{ marginLeft: 30 }}
                />
              </div>
              <StyledInput
                placeholder="Email"
                value={emailState}
                onChange={e => setEmail(e.target.value)}
              />
              <SubmitButton
                onClick={() => updateAlumni(phoneState, slackState, emailState)}
              >
                Submit
              </SubmitButton>
            </Column>
          </InfosWrapper>
        </Row>
        <div>
          <div style={{ marginTop: 30 }}>Enter your LinkedIn profile URL</div>
          <FormInput
            errorMsg={errorMsg}
            successMsg={successMsg}
            onClick={handleSubmit}
            loading={isAlumniLoading}
            placeholder={linkedinUrl}
          />
        </div>
        <ToggleWrapper>
          <NightShift />
        </ToggleWrapper>
      </ProfileWrapper>
    </ProfileContainer>
  )
}

const mapStateToProps = state => {
  return state
}

const mapDispatchToProps = {
  getAlumni,
  updateAlumni,
  updateLinkedin,
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile)
