export const getContent = (contentType, repository, team) => {
  return dispatch => {
    dispatch({
      type: 'GET_CONTENT',
      payload: {
        endpoint: `courses/content/${repository}&${team}&${contentType}`,
        method: 'get',
      },
    })
  }
}
