import styled from '@emotion/styled/macro'

export const LoadingContainer = styled.div`
  height: ${({ isMiddlePage }) => (isMiddlePage ? '80vh;' : 64)};
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const LoaderInner = styled.div`
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  div {
    position: absolute;
    width: 5px;
    height: 5px;
    background: ${({ theme, color }) =>
      color === 'blue' ? theme.colors.azure100 : 'white'};
    border-radius: 50%;
    animation: lds-default 1.2s linear infinite;
  }

  div:nth-of-type(1) {
    animation-delay: 0s;
    top: 29px;
    left: 53px;
  }
  div:nth-of-type(2) {
    animation-delay: -0.1s;
    top: 18px;
    left: 50px;
  }
  div:nth-of-type(3) {
    animation-delay: -0.2s;
    top: 9px;
    left: 41px;
  }
  div:nth-of-type(4) {
    animation-delay: -0.3s;
    top: 6px;
    left: 29px;
  }
  div:nth-of-type(5) {
    animation-delay: -0.4s;
    top: 9px;
    left: 18px;
  }
  div:nth-of-type(6) {
    animation-delay: -0.5s;
    top: 18px;
    left: 9px;
  }
  div:nth-of-type(7) {
    animation-delay: -0.6s;
    top: 29px;
    left: 6px;
  }
  div:nth-of-type(8) {
    animation-delay: -0.7s;
    top: 41px;
    left: 9px;
  }
  div:nth-of-type(9) {
    animation-delay: -0.8s;
    top: 50px;
    left: 18px;
  }
  div:nth-of-type(10) {
    animation-delay: -0.9s;
    top: 53px;
    left: 29px;
  }
  div:nth-of-type(11) {
    animation-delay: -1s;
    top: 50px;
    left: 41px;
  }
  div:nth-of-type(12) {
    animation-delay: -1.1s;
    top: 41px;
    left: 50px;
  }
  @keyframes lds-default {
    0%,
    20%,
    80%,
    100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
  }
`
