import styled from '@emotion/styled/macro'
import theme from '../../theme'

export const CodeContainer = styled.div`
  padding: 30px 30px 30px ${({ type }) => (type === 'ipynb' ? 90 : 30)}px;
  ${({ noPadding }) => (noPadding ? 'padding: 0;' : '')}
  ${({ isFullscreen }) => (isFullscreen ? 'width: 100%;' : '')}
  ${({ isSolution }) => (isSolution ? 'padding: 0;' : '')}

  color: ${({ isNightShift }) =>
    isNightShift ? theme.colors.silver40 : theme.colors.navy500};
  overflow: scroll;

& a {
  word-break: break-word;
}

blockquote {
  position: relative;
  padding-left: 15px;
  &:before {
    content: '';
    content: '';
    width: 5px;
    top: 0;
    position: absolute;
    left: -5px;
    bottom: 0;
    background-color: ${theme.colors.azure100};
  }
}

  & pre {
    margin: 0 !important;
    border-radius: 5px;
    /* background-color: ${({ type, theme, isNightShift, isCourses }) => {
      const background =
        isNightShift || isCourses ? theme.colors.light100 : 'white'
      return type === 'md' ? background : ''
    }}; */
      color: ${({ type, theme }) =>
        type === 'md' ? theme.colors.navy400 : ''};
        color: ${({ isNightShift }) =>
          isNightShift ? theme.colors.silver40 : theme.colors.navy500};
        & code {
          white-space: pre-wrap !important;
          }
          white-space: pre-wrap !important;

  }
  

  & code {
    border-radius: 3px;
    
    background-color: ${({ type, theme, isNightShift, isCourses }) => {
      const background =
        isNightShift || isCourses ? theme.colors.silver40 : 'white'
      return type === 'md' ? background : ''
    }};
    
      padding: 3px;
      color: ${({ type, theme }) =>
        type === 'md' ? theme.colors.navy400 : ''};
  }
  
  & a {
    color: ${({ isNightShift }) =>
      isNightShift ? theme.colors.silver40 : theme.colors.navy500};
    text-decoration: ${theme.colors.azure100} underline !important;
  }
  * > img {
    max-width: 100%;
  }

  & .html-output {
    max-width: 50vw;
    @media screen and (max-width: 1300px) {
      max-width: ${({ isSolution }) => (isSolution ? '40vw' : '50vw')};
  }
  }

/* Corrects the minor Katex bug */
.frac-line {
  border-bottom-style: solid;
}

  .nb-notebook {
    line-height: 1.5;   
    color: ${({ isNightShift }) =>
      isNightShift ? theme.colors.silver40 : theme.colors.navy500}; 
}

.nb-stdout, .nb-stderr {
    white-space: pre-wrap;
    margin: 1em 0;
    padding: 0.1em 0.5em;
}

.nb-stderr {
    background-color: #FAA;
}

.nb-cell + .nb-cell {
    margin-top: 2em;
}

.nb-output table {
  border: 1px solid ${({ theme, isNightShift }) =>
    isNightShift ? theme.colors.silver40 : theme.colors.navy500};
    border-collapse: collapse;
}

.nb-output th {
    font-weight: bold;
}

.nb-output th, .nb-output td {
  border: 1px solid ${({ theme, isNightShift }) =>
    isNightShift ? theme.colors.silver40 : theme.colors.navy500};
    padding: 0.25em;    
    text-align: left;
    vertical-align: middle;
    border-collapse: collapse;
}

table {
  min-height: 1em;    
    width: 80%;
    overflow-x: scroll;
}

table, td, th {
  border-collapse: collapse;
  border: 1px solid ${({ theme, isNightShift }) =>
    isNightShift ? theme.colors.silver40 : theme.colors.navy500};
}

.nb-cell {
    position: relative;    
}

.nb-raw-cell {
    white-space: pre-wrap;
    /* background-color: ${({ theme }) => theme.colors.silver40}; */
    padding: 1em;
    margin: .5em 0;
}

.nb-output {
    min-height: 1em;    
    width: 100%;
    overflow-x: scroll;
    max-height: 24em;
    overflow-y: scroll;
    /* border-right: 1px dotted #CCC; */
    margin-top: 0.5em;
    color: ${({ isNightShift }) =>
      isNightShift ? theme.colors.silver40 : theme.colors.navy500};
}

.nb-output img {
    max-width: 100%;    
}

.nb-output:before, .nb-input:before {
    position: absolute;    
    font-family: monospace;
    color: #999;
    left: -7.5em;
    width: 7em;
    text-align: right;
}

.nb-input {
  max-height: 24em;
  overflow-y: scroll;
}
.nb-input:before {
    content: "In[" attr(data-prompt-number) "]:";
    color: ${({ theme }) => theme.colors.azure100};
}
.nb-output:before {
    content: "Out[" attr(data-prompt-number) "]:";
    color:#D84315;
}

div[style="max-height:1000px;max-width:1500px;overflow:auto;"] {
    max-height: none !important;    
}
code[class*="language-"],
pre[class*="language-"] {
    color: black;
    font-family: monospace !important;
    direction: ltr;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    line-height: 1.5;
    background: ${({ theme }) => theme.colors.silver40};
    tab-size: 4;
    hyphens: none;
}


pre[class*="language-"]::selection, pre[class*="language-"] ::selection,
code[class*="language-"]::selection, code[class*="language-"] ::selection {
    text-shadow: none;
    background: ${({ theme }) => theme.colors.silver40};
}

@media print {
    code[class*="language-"],
    pre[class*="language-"] {
        text-shadow: none;
    }
}

/* Code blocks */
pre[class*="language-"] {
    padding: 1em;
    margin: .5em 0;
    overflow: auto;
}

:not(pre) > code[class*="language-"],
pre[class*="language-"] {
    background: ${({ theme }) => theme.colors.silver40};
}

/* Inline code */
:not(pre) > code[class*="language-"] {
    padding: .1em;
    border-radius: .3em;
}

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
    color: slategray;
}

.token.punctuation {
    color: #999;
}

.namespace {
    opacity: .7;
}

.token.property,
.token.tag,
.token.boolean,
.token.number,
.token.constant,
.token.symbol,
.token.deleted {
    color: #905;
}

.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
    color: #690;
}

.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string {
    color: #a67f59;
}

.token.atrule,
.token.attr-value,
.token.keyword {
    color: #07a;
}

.token.function {
    color: #DD4A68;
}

.token.regex,
.token.important,
.token.variable {
    color: #e90;
}

.token.important,
.token.bold {
    font-weight: bold;
}
.token.italic {
    font-style: italic;
}

.token.entity {
    cursor: help;
}


strong {
  word-break: break-word;
}
`
