import React from 'react'

import { ProgressContainer, Progress } from './style'

const ProgressBar = ({
  percentage,
  height = 10,
  primaryColor,
  secondaryColor,
  borderRadius = 2,
}) => (
  <ProgressContainer
    height={height}
    color={secondaryColor}
    borderRadius={borderRadius}
  >
    <Progress
      percentage={percentage}
      color={primaryColor}
      height={height}
      borderRadius={borderRadius}
    />
  </ProgressContainer>
)

export default ProgressBar
