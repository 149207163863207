const azure100 = '#15E5D6'
const navy500 = '#21212B'
const navy400 = '#23232E'
const navy200 = '#252531'
const navy100 = '#2E2E3C'
const navy50 = '#51526C'
const navy40 = '#373748'
const grey100 = '#8D8D96'
const grey40 = '#C4C4C4'
const silver40 = '#F0F0F4'
const silver100 = '#9FA0B7'
const light100 = '#E8E8ED'
const light400 = '#9595b1'
const purple100 = '#5F43A9'
const purple40 = '#CEC5E8'
const error = '#E94136'
const success = '#19A171'

const theme = {
  colors: {
    azure100: azure100,
    navy500: navy500,
    navy400: navy400,
    navy200: navy200,
    navy100: navy100,
    navy50: navy50,
    navy40: navy40,
    grey100: grey100,
    grey40: grey40,
    silver40: silver40,
    silver100: silver100,
    light100: light100,
    light400: light400,
    purple100: purple100,
    purple40: purple40,
    error: error,
    success: success,
  },
}

export default theme
