import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { ThemeProvider } from 'emotion-theming'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { createLogger } from 'redux-logger'

import middleware from './store/middleware'
import rootReducer from './store/index'
import Courses from './containers/Courses'
import Content from './containers/Content'
import Tutorials from './containers/Tutorials'
import Layout from './components/Layout'
import ExceptionHandler from './components/ExceptionHandler'
import ChooseCurriculum from './containers/ChooseCurriculum'
import Alumni from './containers/Alumni'
import UserProfile from './containers/UserProfile'

import theme from './theme'

const isProd = process.env.NODE_ENV !== 'development'

// Redux logger
const logger = createLogger({
  collapsed: () => true,
})

// Redux Store
const store = isProd
  ? createStore(rootReducer, applyMiddleware(thunk, middleware))
  : createStore(rootReducer, applyMiddleware(thunk, middleware, logger))

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Layout>
          <Switch>
            <Route exact path="/" render={() => <Content type="dashboard" />} />
            <Route path="/courses/:chapterName" component={Courses} />
            <Route path="/courses/" component={Courses} />
            <Route
              path="/setup/:chapterName"
              render={props => <Tutorials {...props} type={'setup'} />}
            />
            <Route
              path="/setup/"
              render={props => <Tutorials {...props} type={'setup'} />}
            />
            <Route
              path="/tutorials/:chapterName"
              render={props => <Tutorials {...props} type={'tutorials'} />}
            />
            <Route
              path="/tutorials/"
              render={props => <Tutorials {...props} type={'tutorials'} />}
            />
            <Route path="/alumni" component={Alumni} />
            <Route
              path="/finalproject"
              render={() => <Content type="finalproject" />}
            />
            <Route path="/choosecurriculum" component={ChooseCurriculum} />
            <Route path="/user" component={UserProfile} />
            <Route
              path="/unauthorized"
              render={() => <ExceptionHandler exceptionType="unauthorized" />}
            />
            <Route render={() => <ExceptionHandler exceptionType="404" />} />
          </Switch>
        </Layout>
      </ThemeProvider>
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
)
