import styled from '@emotion/styled/macro'
import { ReactComponent as Logout } from '../../assets/icons/logout.svg'
import { ReactComponent as Switch } from '../../assets/icons/switch.svg'
import { ReactComponent as User } from '../../assets/icons/user.svg'

export const PopoverItem = styled.div`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.navy100};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0;
  &:first-of-type {
    padding-top: 10px;
  }
  &:last-of-type {
    padding-bottom: 10px;
  }
  &:not(:last-of-type),
  &:first-of-type {
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey40};
  }
  &:hover {
    text-decoration: underline;
  }
`

export const PopoverContentWrapper = styled.div`
  background-color: white;
  padding: 0 10px;
`

export const LogoutIcon = styled(Logout)`
  height: 10px;
  width: 10px;
  margin-right: 10px;
`

export const SwitchIcon = styled(Switch)`
  width: 10px;
  height: 10px;
  margin-right: 10px;
`

export const UserIcon = styled(User)`
  width: 10px;
  height: 10px;
  margin-right: 10px;
`
