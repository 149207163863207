export const setNightShift = isNightShift => {
  return dispatch => {
    dispatch({
      type: 'SET_NIGHT_SHIFT',
      payload: {
        isNightShift,
      },
    })
  }
}
