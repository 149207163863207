import styled from '@emotion/styled/macro'
import { Link } from 'react-router-dom'

export const Title = styled(Link)`
  color: ${({ isNightShift, theme }) =>
    isNightShift ? 'white' : theme.colors.navy400};
  font-weight: 500;
  font-size: 24px;
  padding-left: 50px;
  padding-top: 30px;
`

export const Subtitle = styled.div`
  color: ${({ isNightShift, theme }) =>
    isNightShift ? 'white' : theme.colors.navy100};
  font-weight: 500;
  font-size: 18px;
  padding-left: 50px;
  padding-top: 30px;
`

export const Container = styled.div`
  display: flex;
  position: absolute;
  left: 90px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background-color: ${({ theme, isNightShift }) =>
    isNightShift ? theme.colors.navy100 : theme.colors.light100};
  flex-direction: column;
  overflow: scroll;
`

export const NightShiftWrapper = styled.div`
  position: absolute;
  right: 50px;
  top: 30px;
`
