import React from 'react'

import ReactMarkdown from 'react-markdown'
import Highlight, { defaultProps } from 'prism-react-renderer'
import theme from 'prism-react-renderer/themes/dracula'
//eslint-disable-next-line
import Prism from 'prismjs'
import 'prismjs/components/prism-python.js'
import 'katex/dist/katex.min.css'

import { CodeContainer } from './style'

const CodeDisplay = ({
  type,
  content,
  isFullscreen,
  isNightShift,
  isSolution,
  isCourses,
  noPadding,
}) => {
  let code = null

  if (type === 'ipynb') {
    const replaced = content.replace(/‹/g, '<').replace(/›/g, '>')
    code = <div dangerouslySetInnerHTML={{ __html: replaced }} />
  } else if (type === 'md') {
    code = <ReactMarkdown source={content} escapeHtml={false} />
  } else if (type === 'py') {
    code = (
      <Highlight
        {...defaultProps}
        code={content}
        language="python"
        theme={theme}
      >
        {({ className, style, tokens, getLineProps, getTokenProps }) => (
          <pre className={className} style={style}>
            {tokens.map((line, i) => (
              <div {...getLineProps({ line, key: i })}>
                {line.map((token, key) => (
                  <span {...getTokenProps({ token, key })} />
                ))}
              </div>
            ))}
          </pre>
        )}
      </Highlight>
    )
  }

  return (
    <CodeContainer
      type={type}
      isFullscreen={isFullscreen}
      isNightShift={isNightShift}
      isSolution={isSolution}
      isCourses={isCourses}
      noPadding={noPadding}
    >
      {code}
    </CodeContainer>
  )
}

export default CodeDisplay
