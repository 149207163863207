import React, { Component } from 'react'
import { connect } from 'react-redux'

import { setNightShift } from '../../store/appearance/actions'
import { ToggleContainer, ToggleInner } from './style'

class NightShift extends Component {
  handleClick = () => {
    const { setNightShift, isNightShift } = this.props
    localStorage.setItem('isNightShift', !isNightShift)
    setNightShift(!isNightShift)
  }

  render() {
    const { isNightShift } = this.props

    return (
      <ToggleContainer>
        <ToggleInner onClick={this.handleClick} isActive={isNightShift} />
      </ToggleContainer>
    )
  }
}

const mapDispatchToProps = {
  setNightShift,
}

const mapStateToProps = ({ appearance }) => {
  return appearance
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NightShift)
