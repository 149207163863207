export const getSections = (repository, team, type) => {
  return dispatch => {
    dispatch({
      type: 'GET_SECTIONS',
      payload: {
        endpoint: `/courses/sections/${repository}&${team}&${type}`,
        method: 'get',
      },
    })
  }
}

export const getChapter = (chapterName, repository, team, type) => {
  return dispatch => {
    dispatch({
      type: 'GET_CHAPTER',
      payload: {
        endpoint: `/courses/chapter/${repository}&${team}&${chapterName}&${type}`,
        method: 'get',
      },
    })
  }
}

export const getChallenges = (chapterName, repository, team, type) => {
  return dispatch => {
    dispatch({
      type: 'GET_CHALLENGES',
      payload: {
        endpoint: `/courses/challenges/${repository}&${team}&${chapterName}&${type}`,
        method: 'get',
      },
    })
  }
}

export const getChallenge = challengeId => {
  return dispatch => {
    dispatch({
      type: 'GET_CHALLENGE',
      payload: {
        challengeId: challengeId,
      },
    })
  }
}

export const getNextFlashcard = (finishedFlashcard, isSuccess) => {
  return dispatch => {
    dispatch({
      type: 'GET_NEXT_FLASHCARD',
      payload: {
        finishedFlashcard,
        isSuccess,
      },
    })
  }
}

export const resetFlashcards = () => {
  return dispatch => {
    dispatch({
      type: 'RESET_FLASHCARDS',
    })
  }
}
export const resetCourses = () => {
  return dispatch => {
    dispatch({
      type: 'RESET_COURSES',
    })
  }
}
