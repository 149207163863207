import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { getContent } from '../../store/content/actions'
import { getUserAction } from '../../store/user/actions'

import NightShift from '../NightShift'
import CodeDisplay from '../../components/CodeDisplay'
import Loader from '../../components/Loader'

import { DashboardWrapper, ToggleWrapper, LoadingWrapper } from './style'

const Content = ({
  getContent,
  content,
  appearance,
  type,
  user,
  getUserAction,
}) => {
  const { activeRepository, activeTeam } = user

  useEffect(() => {
    getUserAction()
    if (!content.content.name && activeTeam) {
      getContent(type, activeRepository, activeTeam)
    }
  }, [
    content.content.name,
    getContent,
    type,
    activeRepository,
    activeTeam,
    getUserAction,
  ])

  const { isNightShift } = appearance

  return (
    <DashboardWrapper isNightShift={isNightShift}>
      {content.isLoading ? (
        <LoadingWrapper>
          <Loader />
        </LoadingWrapper>
      ) : (
        <CodeDisplay
          content={content.content}
          type={'md'}
          isFullscreen
          isNightShift={isNightShift}
        />
      )}
      <ToggleWrapper>
        <NightShift />
      </ToggleWrapper>
    </DashboardWrapper>
  )
}

const mapStateToProps = state => {
  return state
}

const mapDispatchToProps = {
  getContent,
  getUserAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(Content)
