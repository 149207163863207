import styled from '@emotion/styled/macro'
import { Link } from 'react-router-dom'

export const ExceptionHandlerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  padding: 50px;
  top: 0;
  left: ${({ isMobile }) => (isMobile ? 0 : '90px')};
  z-index: ${({ isMobile }) => (isMobile ? 3 : 0)};
  @media screen and (min-width: 600px) {
    display: ${({ isMobile }) => (isMobile ? 'none' : 'flex')};
  }
  bottom: 0;
  right: 0;
  background-color: ${({ theme }) => theme.colors.navy100};
`

export const HomeLink = styled(Link)`
  padding-top: 15px;
  text-decoration: ${({ theme }) => theme.colors.azure100} underline !important;
`
export const ExceptionText = styled.div``
