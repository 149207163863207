import styled from '@emotion/styled/macro'
import { css } from '@emotion/core'

export const GlobalStyle = (theme, isNightShift) => css`
  body {
    margin: 0;
    font-family: 'Rubik', sans-serif;
    height: 100%;
    color: ${theme.colors.silver40};
    background: ${isNightShift ? theme.colors.navy200 : theme.colors.light100};
  }

  * {
    /* font-family: 'Rubik', sans-serif; */
    ::selection {
      background: ${theme.colors.azure100} !important;
    }
  }
  a {
    text-decoration: none;
    color: ${theme.colors.silver40};
  }
  #root {
    height: 100%;
  }

  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 200ms ease-in-out;
  }

  .ReactModal__Overlay--after-open {
    opacity: 1;
  }

  .ReactModal__Overlay--before-close {
    opacity: 0;
  }
`

export const LayoutContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 90px;
`
