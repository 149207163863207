import styled from '@emotion/styled/macro'
import { Tabs } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import isPropValid from '@emotion/is-prop-valid'

import { ReactComponent as VideoIcon } from '../../../../assets/icons/video.svg'
import { ReactComponent as LectureIcon } from '../../../../assets/icons/lecture.svg'
import { ReactComponent as ChallengeIcon } from '../../../../assets/icons/challenge.svg'
import { ReactComponent as CardIcon } from '../../../../assets/icons/cards.svg'

export const ChapterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 50px;
  overflow-y: scroll;
  position: ${({ type }) => (type === 'curriculum' ? 'absolute' : 'relative')};
  right: 0;
  left: ${({ type }) => (type === 'curriculum' ? '340px' : '0px')};
  right: 0;
  top: 0;
  bottom: 0;
  color: ${({ isNightShift, theme }) =>
    isNightShift ? theme.colors.silver40 : theme.colors.navy500};
  @media screen and (max-width: 1100px) {
    left: ${({ type }) => (type === 'curriculum' ? '270px' : '0px')};
  }
`

export const TabsWrapper = styled(Tabs, {
  shouldForwardProp: isPropValid,
})`
  & .react-tabs__tab-list {
    border-bottom: 0;
    margin: 0;
  }
  & .react-tabs__tab {
    color: ${({ isNightShift, theme }) =>
      isNightShift ? theme.colors.silver40 : theme.colors.navy500};
    border: none;
    border-radius: 8px 8px 0 0;
    z-index: 0;
  }
  & .react-tabs__tab--selected,
  & .react-tabs__tab--selected:focus {
    box-shadow: none;
    background-color: ${({ isNightShift, theme }) =>
      isNightShift ? theme.colors.navy100 : 'white'};
    &:after {
      background-color: ${({ isNightShift, theme }) =>
        isNightShift ? theme.colors.navy100 : 'white'};
      left: 0;
    }
  }

  & .react-tabs__tab--selected {
    &:after {
      content: '';
      background-color: ${({ isNightShift, theme }) =>
        isNightShift ? theme.colors.navy100 : 'white'};
      left: 0;
      position: absolute;
      height: 5px;
      right: 0;
      bottom: -5px;
    }
  }

  & .react-tabs__tab-panel--selected {
    background-color: ${({ isNightShift, theme }) =>
      isNightShift ? theme.colors.navy100 : 'white'};
    border-radius: 8px;
  }

  & h1 {
    margin: 0;
  }
`

export const Breadcrumb = styled.span``

export const PanelContainer = styled.div`
  padding: 30px;

  & iframe {
    @media screen and (max-width: 1140px) {
      width: 500px;
    }
  }
`

export const TabContent = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 50px 12px 20px;
  @media screen and (max-width: 1100px) {
    padding: 12px 20px 12px 15px;
  }
`

export const TabLabel = styled.span`
  padding-left: 15px;
`

export const InvisibleTrick = styled.div``

export const HeadWrapper = styled.div`
  display: flex;
  padding: 15px 0;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const VideoIconWrapper = styled(VideoIcon, {
  shouldForwardProp: isPropValid,
})`
  path {
    fill: ${({ isNightShift, theme }) =>
      isNightShift ? theme.colors.silver40 : theme.colors.navy500};
  }
`
export const ChallengeIconWrapper = styled(ChallengeIcon, {
  shouldForwardProp: isPropValid,
})`
  path {
    fill: ${({ isNightShift, theme }) =>
      isNightShift ? theme.colors.silver40 : theme.colors.navy500};
  }
`

export const CardIconWrapper = styled(CardIcon, {
  shouldForwardProp: isPropValid,
})`
  path {
    fill: ${({ isNightShift, theme }) =>
      isNightShift ? theme.colors.silver40 : theme.colors.navy500};
  }
`

export const LectureIconWrapper = styled(LectureIcon, {
  shouldForwardProp: isPropValid,
})`
  path {
    fill: ${({ isNightShift, theme }) =>
      isNightShift ? theme.colors.silver40 : theme.colors.navy500};
  }
`
