import styled from '@emotion/styled/macro'
import { ReactComponent as FlipIcon } from '../../../../assets/icons/refresh.svg'
// import 'fonts.googleapis.com/earlyaccess/notosans.css'

export const EndWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.silver40};
  border-radius: 15px;
  /* box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23); */
  color: ${({ theme }) => theme.colors.navy100};
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 50px 100px;
  font-size: 18px;
`

export const ScoreWrapper = styled.div`
  font-size: 36px;
  padding: 50px 30px 15px 30px;
`

export const StatusItem = styled.div`
  font-family: 'Noto Sans', sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.grey100};
  width: 100px;
  padding-top: 35px;
`
export const StatusText = styled.div`
  display: flex;
  justify-content: space-between;
`

export const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 30px;
  @media screen and (max-width: 900px) {
    display: none;
  }
`
export const FlashcardTab = styled.div`
  display: flex;
  justify-content: center;
  height: 700px;
`
export const FlashcardCount = styled.div`
  color: ${({ theme }) => theme.colors.grey100};
  font-size: 13px;
  margin-bottom: 5px;
`

export const FlashcardContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
`

const styledButton = styled.div`
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 200px;
  background-color: ${({ theme }) => theme.colors.navy100};
  justify-content: center;
  align-items: center;
  display: flex;
  color: white;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.navy40};
  }
`

export const FlipButton = styled(styledButton)`
  position: absolute;
  bottom: 30px;
  margin-bottom: 0;
`

export const RetryButton = styled(styledButton)`
  margin-top: 50px;
`

export const UserAnswerLabel = styled.div`
  justify-content: center;
  display: flex;
  margin-bottom: 10px;
`

export const UserAnswerWrapper = styled.div`
  margin: 25px 0;
`

export const UserAnswer = styled.div`
  height: 28px;
  background-color: ${({ theme, userAnswer }) =>
    userAnswer ? theme.colors.silver40 : 'transparent'};
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  color: ${({ theme }) => theme.colors.navy100};
`

export const NextButton = styled.div`
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 170px;
  display: flex;
  justify-content: center;
  color: white;
  background-color: ${({ theme, isSuccess }) =>
    isSuccess ? theme.colors.purple100 : theme.colors.navy100};
  cursor: pointer;
`
export const NextButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`
export const StyledInput = styled.input`
  border-radius: 5px;
  border: solid 0px ${({ theme }) => theme.colors.navy100};
  &:focus {
    outline: none;
  }
  &::placeholder {
    font-size: 14px;
    padding-left: 5px;
  }
  position: absolute;
  bottom: 90px;
  padding: 10px 10px;
  width: 300px;
  @media screen and (max-width: 750px) {
    width: 120px;
  }
`

export const FlipIconWrapper = styled(FlipIcon)`
  margin-left: -10px;
  margin-right: 10px;
`

export const QuestionSideWrapper = styled.div`
  position: absolute;
  backface-visibility: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 500px;
  background-color: ${({ theme }) => theme.colors.silver40};
  border-radius: 15px;
  transition: transform 0.6s ease;
  ${({ isQuestion }) => (isQuestion ? '' : 'transform: rotateY(-180deg)')};
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
`

export const AnswerSideWrapper = styled.div`
  position: absolute;
  backface-visibility: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 500px;
  background-color: ${({ theme }) => theme.colors.silver40};
  border-radius: 15px;
  transition: transform 0.6s ease;
  transform: ${({ isQuestion }) =>
    isQuestion ? 'rotateY(180deg)' : 'rotateY(0deg)'};
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const CardWrapper = styled.div`
  position: relative;
  height: 500px;
  perspective: 150em;
`

export const QuestionContainer = styled.div`
  position: absolute;
  padding: 30px;
  overflow: scroll;
  max-height: 300px;
`
export const AnswerContainer = styled.div`
  position: absolute;
  padding: 30px;
  overflow: scroll;
  max-height: 450px;
  max-width: 500px;
`
