import React from 'react'

import {
  Content,
  ChapterElem,
  Wrapper,
  Cover,
  Title,
  Description,
  Grid,
  SectionTitle,
} from './style'

const ChaptersGrid = ({ chapters, type, storeType }) => {
  return (
    <Grid>
      {chapters.map((chapter, index) => {
        const { name, coverUrl, coverDescription, sectionName } = chapter
        return (
          <ChapterElem
            key={`chapter_${index}`}
            to={{ pathname: `/${type}/${name}` }}
          >
            {type === storeType && (
              <Wrapper>
                <Cover background={coverUrl} />
                <Content>
                  <Title>
                    {name &&
                      name
                        .slice(3)
                        .split('-')
                        .join(' ')}{' '}
                  </Title>
                  <Description>{coverDescription}</Description>
                  {type === 'tutorials' && (
                    <SectionTitle>
                      {sectionName &&
                        sectionName
                          .slice(3)
                          .split('-')
                          .join(' ')}{' '}
                    </SectionTitle>
                  )}
                </Content>
              </Wrapper>
            )}
          </ChapterElem>
        )
      })}
    </Grid>
  )
}

export default ChaptersGrid
