const initialState = {
  sections: [],
  chapter: {},
  challenges: [],
  challengeId: 0,
  isChapterLoading: false,
  isChallengesLoading: false,
  flashcards: [],
  successFlashcardList: [],
  failFlashcardList: [],
  remainingCards: [],
  currentFlashcard: {},
  unavailableStyle: 'shaded',
  storeType: '',
}

const coursesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_SECTIONS:SUCCESS': {
      const { payload } = action

      return {
        ...state,
        unavailableStyle: payload.unavailableStyle,
        sections: payload.sectionList,
        storeType: payload.type,
      }
    }
    case 'GET_CHAPTER:LOADING': {
      return { ...state, isChapterLoading: true }
    }
    case 'GET_CHALLENGES:LOADING': {
      return { ...state, isChallengesLoading: true }
    }
    case 'GET_CHAPTER:SUCCESS': {
      const { payload } = action
      const { flashcards } = payload
      return {
        ...state,
        chapter: payload,
        isChapterLoading: false,
        flashcards: flashcards,
        remainingCards: flashcards,
        currentFlashcard:
          flashcards &&
          flashcards[
            Math.floor(Math.random() * Math.floor(flashcards.length - 1))
          ],
      }
    }
    case 'GET_CHALLENGES:SUCCESS': {
      const { payload } = action
      return {
        ...state,
        challenges: payload,
        challengeId: 0,
        isChallengesLoading: false,
      }
    }
    case 'GET_CHALLENGE': {
      const { payload } = action
      const { challengeId } = payload

      return {
        ...state,
        challengeId: challengeId,
      }
    }
    case 'RESET_COURSES': {
      return initialState
    }
    case 'GET_NEXT_FLASHCARD': {
      const { payload } = action
      const { finishedFlashcard, isSuccess } = payload
      const { remainingCards, successFlashcardList, failFlashcardList } = state
      const flashcardId = finishedFlashcard.id
      const filteredRemainingCards = remainingCards.filter(
        card => card.id !== flashcardId
      )
      const randomId = Math.floor(
        Math.random() * Math.floor(filteredRemainingCards.length - 1)
      )
      const isEnd = filteredRemainingCards.length === 0

      return {
        ...state,
        successFlashcardList: isSuccess
          ? [...successFlashcardList, finishedFlashcard]
          : successFlashcardList,
        failFlashcardList: !isSuccess
          ? [...failFlashcardList, finishedFlashcard]
          : failFlashcardList,
        currentFlashcard: isEnd ? null : filteredRemainingCards[randomId],
        remainingCards: filteredRemainingCards,
      }
    }
    case 'RESET_FLASHCARDS': {
      const { flashcards } = state
      return {
        ...state,
        successFlashcardList: [],
        failFlashcardList: [],
        currentFlashcard:
          flashcards[
            Math.floor(Math.random() * Math.floor(flashcards.length - 1))
          ],
        remainingCards: flashcards,
      }
    }
    default:
      return state
  }
}
export default coursesReducer
