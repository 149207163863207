import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

const ChooseCurriculum = ({ history, activeTeam, activeRepository }) => {
  if (activeTeam && activeRepository) {
    history.push('/')
  }
  return <div />
}

const mapStateToProps = ({ user }) => user

export default withRouter(connect(mapStateToProps)(ChooseCurriculum))
