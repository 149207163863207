import React, { Component } from 'react'

import Loader from '../Loader'

import {
  Button,
  FormInputContainer,
  FormInputWrapper,
  FormInputElement,
  ErrorMessage,
  SuccessMessage,
  LoaderWrapper,
} from './style'

class FormInput extends Component {
  constructor(props) {
    super(props)
    this.myRef = React.createRef()
  }

  state = {
    value: '',
    isSubmitted: false,
  }

  handleSubmit = async () => {
    const { onClick } = this.props
    const { value } = this.state
    if (onClick) {
      this.setState({ isSubmitted: true })
      onClick(value)
    }
  }

  handleInput = e => {
    const value = e.target.value
    this.setState({ value })
  }

  validateFunction = value => {
    // if (value.length < 29) {
    //   return false
    // } else {
    return true
    // }
  }
  render() {
    const { value } = this.state
    const { loading, errorMsg, placeholder, successMsg } = this.props
    const isButtonDisabled = !this.validateFunction(value)

    return (
      <FormInputContainer>
        <FormInputWrapper>
          <FormInputElement
            value={value}
            placeholder={placeholder}
            onChange={this.handleInput}
            ref={this.myRef}
          />
          <Button isDisabled={isButtonDisabled} onClick={this.handleSubmit}>
            {loading ? (
              <LoaderWrapper>
                <Loader color="white" isMiddlePage={false} />
              </LoaderWrapper>
            ) : (
              'Submit'
            )}
          </Button>
        </FormInputWrapper>
        {errorMsg && <ErrorMessage>{errorMsg}</ErrorMessage>}
        {successMsg && <SuccessMessage>{successMsg}</SuccessMessage>}
      </FormInputContainer>
    )
  }
}

export default FormInput
