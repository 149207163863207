import React from 'react'
import { Tab, TabList, TabPanel } from 'react-tabs'

import Lecture from '../Lecture'
import Loader from '../../../../components/Loader'
import Challenges from '../Challenges'
import Flashcard from '../Flashcard'

import {
  ChapterWrapper,
  TabsWrapper,
  PanelContainer,
  TabContent,
  TabLabel,
  Breadcrumb,
  HeadWrapper,
  InvisibleTrick,
  VideoIconWrapper,
  ChallengeIconWrapper,
  CardIconWrapper,
  LectureIconWrapper,
} from './style'

const ChapterInner = ({
  chapter,
  flashcards,
  challenges,
  isLoading,
  getChallenge,
  challengeId,
  isNightShift,
  successFlashcardList,
  failFlashcardList,
  currentFlashcard,
  getNextFlashcard,
  resetFlashcards,
}) => {
  const isChallengesEmpty = challenges.length === 0

  const isVideoUrlEmpty = !chapter.videoUrl
  const isFlashcardsEmpty = !flashcards || flashcards.length === 0
  return (
    <TabsWrapper isNightShift={isNightShift} forceRenderTabPanel>
      <TabList>
        <Tab>
          <TabContent>
            <LectureIconWrapper isNightShift={isNightShift} />
            <TabLabel>Lecture</TabLabel>
          </TabContent>
        </Tab>
        {(!isVideoUrlEmpty || isLoading) && (
          <Tab>
            <TabContent>
              <VideoIconWrapper isNightShift={isNightShift} />
              <TabLabel>Video</TabLabel>
            </TabContent>
          </Tab>
        )}
        {(!isChallengesEmpty || isLoading) && (
          <Tab>
            <TabContent>
              <ChallengeIconWrapper isNightShift={isNightShift} />
              <TabLabel>Challenges</TabLabel>
            </TabContent>
          </Tab>
        )}
        {(!isFlashcardsEmpty || isLoading) && (
          <Tab>
            <TabContent>
              <CardIconWrapper isNightShift={isNightShift} />
              <TabLabel>Flashcards</TabLabel>
            </TabContent>
          </Tab>
        )}
      </TabList>
      {isLoading ? (
        <>
          <TabPanel>
            <Loader />
          </TabPanel>
          <TabPanel>
            <Loader />
          </TabPanel>
          <TabPanel>
            <Loader />
          </TabPanel>
          <TabPanel>
            <Loader />
          </TabPanel>
        </>
      ) : (
        <>
          <TabPanel>
            <Lecture
              lecture={chapter.lectureContent}
              lectureType={chapter.lectureType}
              chapterName={chapter.name}
              section={chapter.section}
              isNightShift={isNightShift}
            />
          </TabPanel>
          {!isVideoUrlEmpty && (
            <TabPanel>
              <PanelContainer isVideoPanel>
                <iframe
                  id="ytplayer"
                  type="text/html"
                  width="640"
                  height="360"
                  src={chapter.videoUrl}
                  frameBorder="0"
                  title="coursePlayer"
                />
              </PanelContainer>
            </TabPanel>
          )}
          {!isChallengesEmpty && (
            <TabPanel>
              <PanelContainer>
                <Challenges
                  challenges={challenges}
                  getChallenge={getChallenge}
                  challengeId={challengeId}
                  isNightShift={isNightShift}
                />
              </PanelContainer>
            </TabPanel>
          )}
          {!isFlashcardsEmpty && (
            <TabPanel>
              <PanelContainer>
                <Flashcard
                  flashcards={flashcards}
                  successFlashcardList={successFlashcardList}
                  failFlashcardList={failFlashcardList}
                  currentFlashcard={currentFlashcard}
                  getNextFlashcard={getNextFlashcard}
                  resetFlashcards={resetFlashcards}
                />
              </PanelContainer>
            </TabPanel>
          )}
        </>
      )}
    </TabsWrapper>
  )
}

const Chapter = ({
  courses,
  chapterId,
  getChallenge,
  isNightShift,
  getNextFlashcard,
  resetFlashcards,
  scrollHandler,
  type,
}) => {
  const {
    chapter,
    challenges,
    challengeId,
    isChapterLoading,
    isChallengesLoading,
    flashcards,
    successFlashcardList,
    failFlashcardList,
    currentFlashcard,
  } = courses

  const isLoading = isChapterLoading || isChallengesLoading
  const isActiveChapter = chapterId !== ''
  const chapterNumber = chapter.name ? chapter.name.substring(0, 3) : null
  const chapterName = chapter.name
    ? chapter.name
        .substring(3)
        .split('-')
        .join(' ')
    : null
  return (
    <ChapterWrapper
      isNightShift={isNightShift}
      id="chapter_wrapper"
      onScroll={() => {
        scrollHandler()
      }}
      type={type}
    >
      <HeadWrapper id="header_tabs">
        {isActiveChapter ? (
          <Breadcrumb>
            {chapter.section &&
              chapter.section
                .substring(3)
                .slice()
                .split('-')
                .join(' ')}{' '}
            / {chapterNumber}
            {chapterName}
          </Breadcrumb>
        ) : (
          <InvisibleTrick />
        )}
      </HeadWrapper>
      {isActiveChapter && (
        <ChapterInner
          chapter={chapter}
          flashcards={flashcards}
          successFlashcardList={successFlashcardList}
          failFlashcardList={failFlashcardList}
          currentFlashcard={currentFlashcard}
          challenges={challenges}
          isLoading={isLoading}
          getChallenge={getChallenge}
          challengeId={challengeId}
          isNightShift={isNightShift}
          getNextFlashcard={getNextFlashcard}
          resetFlashcards={resetFlashcards}
        />
      )}
    </ChapterWrapper>
  )
}

export default Chapter
