import styled from '@emotion/styled/macro'

export const CoursesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`

export const CodeWrapper = styled.div`
  padding: 30px;

  * > img {
    max-width: 800px;
  }
`

export const NightShiftWrapper = styled.div`
  position: absolute;
  right: 50px;
  top: 30px;
`
