const initialState = {
  path: '',
  content: '',
  isLoading: false,
}

const contentReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_CONTENT:LOADING': {
      return { ...state, isLoading: true }
    }
    case 'GET_CONTENT:SUCCESS': {
      const { payload } = action
      return {
        content: payload.content,
        path: payload.path,
        isLoading: false,
      }
    }
    default:
      return state
  }
}
export default contentReducer
