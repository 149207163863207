import React, { Component } from 'react'

import CodeDisplay from '../../../../components/CodeDisplay'

import {
  ChallengeListWrapper,
  ChallengeListItem,
  ChallengeContainer,
  CollapseWrapper,
  PanelWrapper,
  ArrowWrapper,
} from './style'

class Challenges extends Component {
  render() {
    const { challenges, getChallenge, challengeId, isNightShift } = this.props
    const selectedChallenge = challenges[challengeId]

    if (!selectedChallenge) return null

    const { solution } = selectedChallenge
    const { solutionType } = selectedChallenge

    const { readme } = selectedChallenge
    const { readmeType } = selectedChallenge

    return (
      <ChallengeContainer>
        <ChallengeListWrapper>
          {challenges.length > 0 &&
            challenges.map(({ name }, id) => {
              const challengeNumber = name.substring(0, 3)
              const challengeName = name
                .substring(3)
                .split('-')
                .join(' ')

              return (
                <ChallengeListItem
                  onClick={() => getChallenge(id)}
                  name={name}
                  id={id}
                  key={`challenge_item${id}`}
                  selected={id === challengeId}
                  isNightShift={isNightShift}
                >
                  {challengeNumber}
                  {challengeName}
                </ChallengeListItem>
              )
            })}
        </ChallengeListWrapper>

        <CollapseWrapper
          defaultActiveKey={'0'}
          expandIcon={() => <ArrowWrapper isNightShift={isNightShift} />}
          isNightShift={isNightShift}
          style={{ display: 'grid' }}
        >
          {readme && (
            <PanelWrapper header="Challenge" showArrow openAnimation>
              <CodeDisplay
                content={readme}
                type={readmeType}
                isNightShift={isNightShift}
                isCourses
              />
            </PanelWrapper>
          )}

          {solution && (
            <PanelWrapper
              header="Solution"
              showArrow
              openAnimation
              style={{ display: 'grid' }}
            >
              <CodeDisplay
                content={solution}
                type={solutionType}
                isSolution
                isCourses
                isNightShift={isNightShift}
              />
            </PanelWrapper>
          )}
        </CollapseWrapper>
      </ChallengeContainer>
    )
  }
}

export default Challenges
