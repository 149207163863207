import React from 'react'

import InnerLoader from '../../../../components/InnerLoader'
import { StyledSelect, LoadingWrapper } from './style'
import theme from '../../../../theme'

const Sort = ({
  options,
  handleChange,
  selectedOption,
  type,
  isMulti = false,
  placeholder = 'select',
  closeMenuOnSelect = true,
  isLoading,
  isEmpty,
}) => {
  const formattedOptions = options.map(option => {
    const label = type === 'filter' ? option : `By ${option}`
    return {
      value: option,
      label,
    }
  })

  const customStyles = {
    valueContainer: (provided, state) => {
      return {
        overflowX: 'scroll',
        width: type === 'filter' ? 280 : 150,
        flexWrap: 'nowrap',

        display: 'flex',
      }
    },
    option: (provided, state) => {
      return {
        ...provided,
        color: theme.colors.navy100,
      }
    },
    multiValue: (provided, state) => {
      return {
        ...provided,
      }
    },
    control: (provided, state) => {
      return {
        ...provided,
        borderColor: 'transparent !important',
        borderRadius: 8,
        boxShadow: '0px 2px 2px rgba(58, 58, 75, 0.25)',
        height: 40,
      }
    },
    placeholder: (provided, state) => {
      return {
        ...provided,
        marginLeft: 8,
        fontSize: 18,
      }
    },
  }

  return (
    <StyledSelect
      menuColor="red"
      value={selectedOption}
      onChange={handleChange}
      options={formattedOptions}
      isMulti={isMulti}
      styles={customStyles}
      closeMenuOnSelect={closeMenuOnSelect}
      isSearchable={type === 'filter'}
      placeholder={placeholder}
      isLoading={isLoading}
      isDisabled={isLoading}
      isEmpty={isEmpty}
      type={type}
      components={{
        LoadingIndicator: () => (
          <>
            {type === 'filter' && !isEmpty && <div style={{ width: 36 }} />}
            <LoadingWrapper>
              <InnerLoader size={12} />
            </LoadingWrapper>
          </>
        ),
      }}
    />
  )
}

export default Sort
