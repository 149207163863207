import styled from '@emotion/styled/macro'
import {
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'
import isPropValid from '@emotion/is-prop-valid'
import { ReactComponent as Arrow } from '../../assets/icons/arrow.svg'

export const AccordionItemButtonWrapper = styled(AccordionItemButton)`
  padding: 15px 20px 15px 30px;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.navy40};
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const PanelWrapper = styled(AccordionItemPanel, {
  shouldForwardProp: isPropValid,
})`
  background-color: ${({ theme, isCourseList }) =>
    isCourseList ? theme.colors.navy400 : theme.colors.navy100};
`

export const ArrowItem = styled(Arrow, { shouldForwardProp: isPropValid })`
  transform: ${({ isToggled }) =>
    isToggled ? 'rotate(0deg)' : 'rotate(-90deg)'};
  transition: 200ms;
  height: 100%;
`

export const HeadingLabel = styled.span`
  padding-right: 10px;
`

export const ArrowWrapper = styled.div`
  display: flex;
  align-items: center;
`
