import React from 'react'
import Cookies from 'js-cookie'
import { Link } from 'react-router-dom'

import {
  PopoverItem,
  PopoverContentWrapper,
  LogoutIcon,
  SwitchIcon,
  UserIcon,
} from './style'

const Popover = ({ openModal }) => {
  return (
    <PopoverContentWrapper>
      <Link to="/user">
        <PopoverItem>
          <UserIcon />
          <div>Edit user</div>
        </PopoverItem>
      </Link>
      <PopoverItem
        onClick={() => {
          openModal()
        }}
      >
        <SwitchIcon />
        <div>Change repo</div>
      </PopoverItem>
      <PopoverItem
        onClick={() => {
          Cookies.remove('jwt')
          localStorage.removeItem('activeRepository')
          localStorage.removeItem('activeTeam')
          window.location = '/'
        }}
      >
        <LogoutIcon />
        <div>Log Out</div>
      </PopoverItem>
    </PopoverContentWrapper>
  )
}

export default Popover
