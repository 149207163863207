import styled from '@emotion/styled/macro'
import isPropValid from '@emotion/is-prop-valid'
import { ReactComponent as ChevronUp } from '../../assets/icons/chevron-up.svg'

export const ScrollTopWrapper = styled(ChevronUp, {
  shouldForwardProp: isPropValid,
})`
  position: absolute;
  cursor: pointer;
  path {
    fill: ${({ isNightShift, theme }) =>
      isNightShift ? theme.colors.silver40 : theme.colors.navy400};
  }
  right: 30px;
  bottom: 30px;
  opacity: ${({ isTop }) => (isTop ? 0 : 1)};
  transition: 200ms;
  &:hover {
    transform: scale(1.1);
  }
`
