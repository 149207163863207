import React, { Component } from 'react'

import AccordionMenu from '../../../../components/Accordion'

import {
  ChaptersListWrapper,
  ChapterItem,
  CoursesListWrapper,
  AccordionWrapper,
  UnavailableChapterItem,
} from './style'

const ChapterItems = ({ chapters = [], activeItem, unavailableStyle }) => {
  return (
    <ChaptersListWrapper>
      {chapters.map((chapter, id) => {
        const { isAvailable } = chapter
        const chapterNumber = chapter.name.substring(0, 3)
        const chapterName = chapter.name
          .substring(3)
          .split('-')
          .join(' ')
        if (!isAvailable) {
          return unavailableStyle === 'hidden' ? (
            <div key={`chapter_item_${id}`} />
          ) : (
            <UnavailableChapterItem key={`chapter_item_${id}`}>
              {chapterNumber}
              {chapterName}
            </UnavailableChapterItem>
          )
        }
        return (
          <ChapterItem
            key={`chapter_item_${id}`}
            to={{ pathname: `/courses/${chapter.name}` }}
            isActive={activeItem === chapter.name}
          >
            {chapterNumber}
            {chapterName}
          </ChapterItem>
        )
      })}
    </ChaptersListWrapper>
  )
}

class CoursesList extends Component {
  state = {
    expandedList: [],
  }

  render() {
    const { sections = [], activeItem, unavailableStyle } = this.props
    const { expandedList } = this.state

    return (
      <CoursesListWrapper>
        <AccordionWrapper
          allowMultipleExpanded
          allowZeroExpanded
          onChange={expanded => this.setState({ expandedList: expanded })}
        >
          {sections.map(({ name, chapters, isAvailable }, id) => {
            const isToggled = expandedList.find(expandedItem => {
              return expandedItem === id
            })
            return unavailableStyle === 'hidden' && !isAvailable ? (
              <div key={`accordion_item_${id}`} />
            ) : (
              <AccordionMenu
                key={`accordion_item_${id}`}
                heading={name
                  .slice(3)
                  .split('-')
                  .join(' ')}
                isToggled={!!isToggled || isToggled === 0}
                isCourseList
                uuid={id}
                panel={
                  <ChapterItems
                    chapters={chapters}
                    activeItem={activeItem}
                    unavailableStyle={unavailableStyle}
                  />
                }
              />
            )
          })}
        </AccordionWrapper>
      </CoursesListWrapper>
    )
  }
}

export default CoursesList
